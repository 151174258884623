import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import i18n from "i18next";
import {
  currentPortfolioCurrencySelector,
  portfolioGroupedFundScheduleSelector,
  fundScheduleDurations,
  getFundScheduleTitle,
  planningVariables
} from "@kubera/common";
import FundScheduleSummaryDialog from "components/net_worth/FundScheduleSummaryDialog";
import ChangeLabel from "components/labels/ChangeLabel";
import ExpandingTaxInput from "components/inputs/ExpandingTaxInput";

const MainDialog = styled(Dialog)`
  position: relative;
  width: 522px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
`;

const TotalHeader = styled.div`
  display: flex;
  margin-top: 10px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const TotalTitle = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.5;
  text-transform: uppercase;
`;

const TotalValue = styled(ChangeLabel)`
  width: fit-content;
  margin-top: 2px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const EmptyValue = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0.7;
  white-space: pre-wrap;
`;

const Link = styled.a`
  color: ${props => props.theme.linkColor};
  margin-top: 25px;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  text-decoration-style: solid;
  line-height: 17px;
  cursor: pointer;
`;

class CapitalScheduleDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFundScheduleDialog: false,
      [planningVariables.TAX]: props.data ? props.data[planningVariables.TAX] : undefined,
      [planningVariables.TAX_DEDUCTION_AMOUNT]: props.data
        ? props.data[planningVariables.TAX_DEDUCTION_AMOUNT]
        : undefined,
      [planningVariables.TAX_DEDUCTION_RATE]: props.data ? props.data[planningVariables.TAX_DEDUCTION_RATE] : undefined
    };
    this.showFundScheduleDialog = this.showFundScheduleDialog.bind(this);
    this.hideFundScheduleDialog = this.hideFundScheduleDialog.bind(this);
    this.updateTaxParams = this.updateTaxParams.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  showFundScheduleDialog() {
    this.setState({ showFundScheduleDialog: true });
  }

  hideFundScheduleDialog() {
    this.setState({ showFundScheduleDialog: false });
  }

  updateTaxParams(params) {
    this.setState({ ...params });
  }

  handleSaveClick() {
    this.props.onVariableUpdate({
      [planningVariables.TAX]: this.state[planningVariables.TAX],
      [planningVariables.TAX_DEDUCTION_AMOUNT]: this.state[planningVariables.TAX_DEDUCTION_AMOUNT],
      [planningVariables.TAX_DEDUCTION_RATE]: this.state[planningVariables.TAX_DEDUCTION_RATE]
    });
    this.props.onDismiss();
  }

  render() {
    const { portfolioGroupedFundSchedule } = this.props;
    const custodianIds = portfolioGroupedFundSchedule.custodianIds;

    if (custodianIds.length === 0) {
      return (
        <DialogOverlay onDismiss={this.props.onDismiss}>
          <MainDialog>
            <Container>
              <Title>{i18n.t("fundScheduleSummaryDialog.title")}</Title>
              <Description>{i18n.t("fundScheduleSummaryDialog.empty")}</Description>
            </Container>
          </MainDialog>
        </DialogOverlay>
      );
    }
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <MainDialog>
          <Container>
            <Title>{i18n.t("capitalSchedule.title")}</Title>
            <TotalHeader>
              {Object.keys(fundScheduleDurations).map(key => {
                if (fundScheduleDurations[key] === fundScheduleDurations.UNSCHEDULED) return null;
                const value = this.props.portfolioGroupedFundSchedule.data[fundScheduleDurations[key]]
                  ? this.props.portfolioGroupedFundSchedule.data[fundScheduleDurations[key]].total
                  : 0;

                return (
                  <TotalContainer key={key}>
                    <TotalTitle>{getFundScheduleTitle(fundScheduleDurations[key])}</TotalTitle>
                    {value ? (
                      <TotalValue
                        currency={this.props.portfolioCurrency}
                        startValue={0}
                        endValue={value}
                        disableColor={true}
                        disableColorCode={"rgba(0,0,0)"}
                        alignPosition={"left"}
                      />
                    ) : (
                      <EmptyValue>{"--"}</EmptyValue>
                    )}
                  </TotalContainer>
                );
              })}
            </TotalHeader>
            <Link onClick={this.showFundScheduleDialog}>{i18n.t("capitalSchedule.link")}</Link>
            <ExpandingTaxInput
              collapsedTitle={"taxDistribution"}
              dropdownWidth={422}
              marginTop={"25px"}
              tax={this.state[planningVariables.TAX]}
              taxDeductionAmount={this.state[planningVariables.TAX_DEDUCTION_AMOUNT]}
              taxDeductionRate={this.state[planningVariables.TAX_DEDUCTION_RATE]}
              defaultVar={planningVariables.TAX_DEDUCTION_AMOUNT}
              updateParams={this.updateTaxParams}
              portfolioCurrency={this.props.portfolioCurrency}
              tickerId={this.state.expectedTickerId}
              deductionDescriptionNoun={i18n.t("yourDistribution")}
              onSaveClick={this.handleSaveClick}
            />
            {this.state.showFundScheduleDialog && <FundScheduleSummaryDialog onDismiss={this.hideFundScheduleDialog} />}
          </Container>
        </MainDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  portfolioCurrency: currentPortfolioCurrencySelector(state),
  portfolioGroupedFundSchedule: portfolioGroupedFundScheduleSelector(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CapitalScheduleDialog);

import axios from "axios";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/browser";
import { store } from "../store";
import eventBus from "../eventBus";
import { Logger } from "../utilities/Logger";
import { ApiMocker } from "./mocks/ApiMocker";
import { ApiResponse, ApiData, ApiError, apiErrorCodes } from "./ApiResponse";
import {
  getAuthToken,
  getIdToken,
  isCustomWhiteLabelHost,
  getPasscodeForViewMode,
  showPasscodeScreen
} from "../utilities/Common";
import { getUuid, getAppVersion } from "../utilities/Number";
import { wlClientContextSelector } from "../redux/reducers/WhiteLabelReducer";
import {
  userSelector,
  isSharedPortfolioUserSelector,
  getPortfolioSessionUserId,
  currentPortfolioIdSelector,
  currentPortfolioSelector
} from "../redux/reducers/Common";
import { isAppInWhiteLabelMode, isAppInViewMode, getTokenForViewMode } from "../utilities/Common";

export class ApiClient {
  static async getAuthenticatedInstance(
    idempotentId,
    withIdHeader,
    withUtmHeader,
    autoKickOut = true,
    configHook = null
  ) {
    const token = await getAuthToken().then(token => {
      return token;
    });
    const config = {
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: token,
        "x-kubera-idempotency-key": idempotentId,
        "x-kubera-request-id": getUuid(),
        "x-kubera-client-version": getAppVersion(),
        "x-kubera-client-type": "web"
      },
      withCredentials: true
    };
    const instance = axios.create(config);
    const shouldUseMockData = process.env.REACT_APP_ENV === "local" && process.env.REACT_APP_HARDCODED_DATA === "1";

    if (process.env.REACT_APP_ENV !== "local" && withIdHeader) {
      const idToken = await getIdToken().then(token => {
        return token;
      });
      instance.defaults.headers["x-kubera-id-token"] = idToken;
    }

    if (process.env.REACT_APP_ENV !== "local" && withUtmHeader) {
      const utm = Cookies.get("k-utm");
      if (utm) {
        instance.defaults.headers["x-kubera-utm"] = utm;
      }
    }

    if (process.env.REACT_APP_503 === "1") {
      eventBus.dispatch("setAppMaintenanceStatus", [true]);
      const errorObj = new ApiError({});
      return Promise.reject(errorObj);
    }

    // If a white-label AMC user is making requests on behalf of a
    // client for their portfolio set correct headers and URLs
    // so that the server can identify the user
    const wlClientContext = wlClientContextSelector(store.getState());
    if (!wlClientContext === false) {
      instance.interceptors.request.use(
        function(config) {
          const whiteListedAdminPaths = [
            "/auth/portfolio",
            "/auth/section",
            "/auth/link",
            "/auth/document",
            "/auth/user/beneficiary"
          ];
          const blackListedAdminPaths = ["/auth/document/photo"];
          const shouldModifyUrl =
            whiteListedAdminPaths.findIndex(path => config.url.startsWith(path)) !== -1 &&
            blackListedAdminPaths.includes(config.url) === false;

          if (shouldModifyUrl === true) {
            config.headers["x-wl-user-id"] = wlClientContext.userId;
            config.url = config.url.replace("/auth/", "/admin/auth/");
          }
          if (!configHook === false) {
            configHook(config);
          }
          return config;
        },
        function(error) {
          return Promise.reject(error);
        }
      );
    } else if (isAppInViewMode() === true) {
      instance.interceptors.request.use(
        function(config) {
          if (config.method !== "get") {
            console.log("Non GET URL:" + config.url);
            return Promise.reject(new Error(""));
          }

          const whiteListedPaths = ["/public/view"];
          const blackListedPaths = ["/auth/user"];
          const shouldModifyUrl = whiteListedPaths.findIndex(path => config.url.startsWith(path)) === -1;

          if (blackListedPaths.includes(config.url) === true) {
            return Promise.reject(new Error(""));
          }

          if (!getTokenForViewMode() === false) {
            config.headers["x-view-token"] = getTokenForViewMode();
          }
          if (!getPasscodeForViewMode() === false) {
            config.headers["x-view-passcode"] = getPasscodeForViewMode();
          }
          if (shouldModifyUrl === true) {
            config.url = config.url.replace("/auth/", "/view/");
          }
          if (!configHook === false) {
            configHook(config);
          }
          return config;
        },
        function(error) {
          return Promise.reject(error);
        }
      );
    } else if (isSharedPortfolioUserSelector(store.getState())) {
      const currentPortfolioId = currentPortfolioIdSelector(store.getState());

      instance.interceptors.request.use(
        function(config) {
          if (
            config.method !== "get" &&
            (config.url.startsWith("/auth/portfolio") || config.url.startsWith("/auth/section")) &&
            ["/auth/portfolio"].includes(config.url) === false
          ) {
            const currentPortfolio = currentPortfolioSelector(store.getState());
            if (currentPortfolio.write === 0) {
              console.log("Non GET portfolio URL from read only portfolio called:" + config.url);
              return Promise.reject(new Error("Attempt to write to a read only shared portfolio"));
            }
          }

          const currentUser = userSelector(store.getState());
          const userId = isAppInWhiteLabelMode()
            ? currentUser
              ? currentUser.parentId
              : null
            : getPortfolioSessionUserId();
          const blackListedFamilyGroups = ["/auth/user", "/auth/document/photo", "/auth/link/popularInstitution"];
          const whiteListedFamilyGroups = [
            "/auth/user/beneficiary",
            "/auth/user/family/member/invite",
            "/auth/user/family/member/updateInvite",
            "/auth/user/family/member/reinvite",
            "/auth/user/family/member/delete"
          ];
          const shouldModifyUrl =
            whiteListedFamilyGroups.findIndex(path => config.url.startsWith(path)) !== -1 ||
            blackListedFamilyGroups.findIndex(path => config.url.startsWith(path)) === -1;
          const isPortfolioDetails =
            config.url === "/auth/portfolio" && !config.params === false && config.params.includeDetails === 1;
          const shouldIncludePortfolioIdHeader =
            isAppInWhiteLabelMode() === false &&
            isPortfolioDetails === false &&
            !currentPortfolioId === false &&
            ["/auth/portfolio"].includes(config.url) === false;

          if (!currentUser === false && currentUser.id !== userId && shouldModifyUrl === true) {
            if (!userId === false) {
              config.headers["x-family-user-id"] = userId;
            }
            if (shouldIncludePortfolioIdHeader) {
              config.headers["x-family-portfolio-id"] = currentPortfolioId;
            }
            if (config.url.includes("/family/auth") === false) {
              config.url = config.url.replace("/auth/", "/family/auth/");
            }
          }
          if (!configHook === false) {
            configHook(config);
          }
          return config;
        },
        function(error) {
          return Promise.reject(error);
        }
      );
    } else if (shouldUseMockData) {
      instance.interceptors.request.use(
        function(config) {
          if (config.method !== "get") {
            console.log("Non GET URL:" + config.url);
            return Promise.reject(new Error("Attempt to write in view only mode"));
          }

          if (ApiMocker.canMockUrl(config.url)) {
            config.adapter = function(config) {
              return new Promise((resolve, reject) => {
                const res = {
                  data: ApiMocker.getMockData(config.url),
                  status: 200,
                  config
                };
                return resolve(res);
              });
            };
          }

          if (!configHook === false) {
            configHook(config);
          }
          return config;
        },
        function(error) {
          return Promise.reject(error);
        }
      );
    } else if (!configHook === false) {
      instance.interceptors.request.use(
        function(config) {
          configHook(config);
          return config;
        },
        function(error) {
          return Promise.reject(error);
        }
      );
    }
    var retryCount = 0;
    instance.interceptors.response.use(
      function(response) {
        const apiResponse = new ApiResponse(response);
        if (apiResponse.isError() === true) {
          return Promise.reject(new ApiError(apiResponse));
        }
        return new ApiData(apiResponse);
      },
      function(error) {
        const apiResponse = new ApiResponse(error.response);
        const isCancel = axios.isCancel(error);
        const isSignoutRequest = !error.response === false && error.response.config.url === "/auth/user/signout";

        if (apiResponse.httpStatus === 503) {
          eventBus.dispatch("setAppMaintenanceStatus", [true]);
          const errorObj = new ApiError(apiResponse);
          return Promise.reject(errorObj);
        } else if (apiResponse.errorCode === apiErrorCodes.CLIENT_BREAKING_CHANGES) {
          eventBus.dispatch("setShowBreakingChangesError");
          const errorObj = new ApiError(apiResponse);
          return Promise.reject(errorObj);
        }

        retryCount++;
        if (error.response) {
        } else if (error.request) {
          apiResponse.errorCode = apiErrorCodes.NO_RESPONSE;
        } else {
          apiResponse.errorCode = apiErrorCodes.INVALID_REQUEST;
        }

        if (
          isSignoutRequest === false &&
          autoKickOut === true &&
          apiResponse.errorCode === apiErrorCodes.SESSION_EXPIRED
        ) {
          if (isAppInViewMode()) {
            showPasscodeScreen();
            return;
          }
          // In case of session expiry refetch the auth token and
          // re-attempt the request one time before throwing the user
          // out. This is to handle the case when a request is made
          // while the Auth module is trying to refresh the token.
          console.log("Session expired ***** Attempt#:" + retryCount);

          if (retryCount < 4) {
            return getAuthToken().then(token => {
              const config = error.config;
              config.headers["Authorization"] = token; // eslint-disable-line dot-notation
              return instance.request(config);
            });
          } else {
            const user = userSelector(store.getState());
            var userId = "<null>";
            if (!user === false) {
              userId = user.id;
            }

            const header = error.config.headers["Authorization"]; // eslint-disable-line dot-notation
            Sentry.withScope(function(scope) {
              scope.setTag("signout_exception", "Uncaught Exception");
              scope.setExtras({
                id: userId,
                header: header,
                ts: new Date().getTime()
              });
              Sentry.captureException("ApiClient signOut triggered. User id:" + userId);
            });

            if (isSignoutRequest === false) {
              eventBus.dispatch("signOut");
            }
            return;
          }
        } else {
          Sentry.withScope(scope => {
            scope.setExtras({
              errorUrl: error.response ? error.response.config.url : "",
              errorResponse: apiResponse
            });
            Sentry.captureMessage(
              "API_ERROR_" + (!apiResponse.errorCode === true ? apiResponse.httpStatus : apiResponse.errorCode)
            );
          });

          Logger.captureException(error);
        }
        if (apiResponse.errorCode === apiErrorCodes.AUTO_UPDATE_DENIED) {
          eventBus.dispatch("fetchUser");
        }
        if (!apiResponse.errorMessage) {
          apiResponse.errorMessage = "Sorry, something’s not right.\nPlease try again.";
        }
        if (!apiResponse.errorCode) {
          apiResponse.errorCode = apiErrorCodes.UNKNOWN_ERROR;
        }
        const errorObj = new ApiError(apiResponse);
        errorObj.isCancel = isCancel;
        return Promise.reject(errorObj);
      }
    );
    return instance;
  }

  static getAppStatusConfig(baseUrl) {
    const requestInstance = axios.create();
    return new Promise((resolve, reject) => {
      requestInstance
        .get(`${baseUrl}/status/config.json`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static async signOut(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/user/signout");
  }

  static async getTickerData(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/utility/tickerInfoV3");
  }

  static async getTickersForText(idempotentId, text, countryCode, date) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const payload = { params: { countryCode: countryCode, name: text } };
    if (date) {
      payload.params.date = date;
    }
    return requestInstance.get("/auth/utility/getTickerInfo", payload);
  }

  static async getTickersForIds(idempotentId, tickerIds) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/utility/getTickerInfoWithId", { params: { ids: tickerIds } });
  }

  static async getUser(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId, true, true);
    return requestInstance.get("/auth/user");
  }

  static async isUserValid(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/user/valid");
  }

  static async setUser(idempotentId, user) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId, true, true);
    return requestInstance.post("/auth/user", user);
  }

  static async getPortfolios(idempotentId, includeDetails = 0, hash = null) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/portfolio", {
      params: { includeDetails: includeDetails, fetchLimit: 250, hash: hash }
    });
  }

  static async createPortfolio(idempotentId, name) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/portfolio", { name: name });
  }

  static async updatePortfolio(
    idempotentId,
    portfolioId,
    name,
    currency,
    notificationOption,
    taxRate,
    tsPlanningTargetDate,
    tsStartDate,
    networthChartScenario
  ) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const dataToUpdate = {
      name: name,
      currency: currency,
      taxRate,
      tsPlanningTargetDate,
      tsStartDate,
      networthChartScenario: JSON.stringify(networthChartScenario)
    };
    if (!notificationOption === false) {
      dataToUpdate.notificationOption = notificationOption;
    }
    return requestInstance.post(`/auth/portfolio/${portfolioId}`, dataToUpdate);
  }

  static async deletePortfolio(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/portfolio/${portfolioId}`);
  }

  static async createPortfolioShareLink(idempotentId, portfolioId, linkDetails) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/share`, linkDetails);
  }

  static async updatePortfolioShareLink(idempotentId, portfolioId, linkId, linkDetails) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/share/${encodeURIComponent(linkId)}`, linkDetails);
  }

  static async deletePortfolioShareLink(idempotentId, portfolioId, linkId) {
    const url = `/auth/portfolio/${portfolioId}/share/${encodeURIComponent(linkId)}`;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.request({ method: "purge", url: url });
  }

  static async getPortfolio(idempotentId, portfolioId, includeDetails = 0) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/${portfolioId}`, { params: { includeDetails: includeDetails } });
  }

  static async createCustodian(idempotentId, sectionId, custodian) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/${sectionId}/custodian`, custodian);
  }

  static async updateCustodian(idempotentId, sectionId, custodianId, custodian) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}`, custodian);
  }

  static async refreshCustodian(idempotentId, custodianId, forceFlag = 0) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/custodian/${encodeURIComponent(custodianId)}/refreshV2/force/${forceFlag}`
    );
  }

  static async refreshCustodianBatch(idempotentId, custodianIds, hash = null, forceUpdate) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      "/auth/section/custodian/batchRefresh",
      !hash === true ? { custodianIds: custodianIds, force: forceUpdate } : { hash: hash, force: forceUpdate }
    );
  }

  static async deleteCustodian(idempotentId, sectionId, custodianIdArray) {
    const data = { custodianId: custodianIdArray };
    const url = `/auth/section/${sectionId}/custodian`;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.request({ method: "delete", url: url, data: data });
  }

  static async restoreCustodian(idempotentId, sectionId, custodianIdArray) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/${sectionId}/custodian/restore`, { custodianId: custodianIdArray });
  }

  static async expandCustodianHoldings(idempotentId, sectionId, custodianId, sectionData) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/expandHolding`,
      sectionData
    );
  }

  static async collapseCustodianHoldings(idempotentId, sectionId, custodianId, destinationSectionId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/collapseHolding`,
      {
        sectionId: destinationSectionId
      }
    );
  }

  static async createSection(idempotentId, portfolioId, sheetId, section) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/sheet/${sheetId}/section`, section);
  }

  static async updateSection(idempotentId, portfolioId, sheetId, sectionId, section) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/sheet/${sheetId}/section/${sectionId}`, section);
  }

  static async updateSheet(idempotentId, portfolioId, sheetId, sheet) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/sheet/${sheetId}`, sheet);
  }

  static async getCustodian(idempotentId, custodianId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/section/custodianInfo/${encodeURIComponent(custodianId)}`);
  }

  static async getCustodianDetails(idempotentId, custodianId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/section/custodian/${encodeURIComponent(custodianId)}`);
  }

  static async getCustodianDetailsHistory(idempotentId, custodianId, timeStamp, costHistory = false) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);

    if (costHistory) {
      return requestInstance.get(`/auth/section/custodian/${encodeURIComponent(custodianId)}/costbasis/${timeStamp}`);
    }
    return requestInstance.get(`/auth/section/custodian/${encodeURIComponent(custodianId)}/history/${timeStamp}`, {
      params: { order: "desc" }
    });
  }

  static async createCustodianHistory(idempotentId, sectionId, custodianId, history) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/history`,
      history
    );
  }

  static async updateCustodianHistory(idempotentId, sectionId, custodianId, history) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/history/${history.id}`,
      history
    );
  }

  static async deleteCustodianHistory(idempotentId, sectionId, custodianId, historyId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/history/${historyId}`
    );
  }

  static async updateCostBasisHistoryForCustodian(idempotentId, sectionId, custodianId, costBasisId, history) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/costbasis/${costBasisId}`,
      history
    );
  }

  static async copyCustodianHistoryAfterDate(idempotentId, sectionId, custodianId, properties) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/setHistoryAfterDate`,
      properties
    );
  }

  static async deleteCostBasisHistoryForCustodian(idempotentId, sectionId, custodianId, costBasisId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/costbasis/${costBasisId}`
    );
  }

  static async archiveCustodian(idempotentId, sectionId, custodianIdArray) {
    const data = { custodianId: custodianIdArray };
    const url = `/auth/section/${sectionId}/custodian`;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.request({ method: "purge", url: url, data: data });
  }

  static async sectionStarAll(idempotentId, sectionId, starValue) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/${sectionId}/star/${starValue}`);
  }

  static async sectionSetAsPast(idempotentId, sectionId, pastValue) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/${sectionId}/past/${pastValue}`);
  }

  static async sheetStarAll(idempotentId, portfolioId, sheetId, starValue) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/sheet/${sheetId}/star/${starValue}`);
  }

  static async sheetSetAsPast(idempotentId, portfolioId, sheetId, pastValue) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/sheet/${sheetId}/past/${pastValue}`);
  }

  static async archiveSection(idempotentId, portfolioId, sheetId, sectionId) {
    const url = `/auth/portfolio/${portfolioId}/sheet/${sheetId}/section/${sectionId}`;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.request({ method: "purge", url: url });
  }

  static async archiveSheet(idempotentId, portfolioId, sheetId) {
    const url = `/auth/portfolio/${portfolioId}/sheet/${sheetId}`;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.request({ method: "purge", url: url });
  }

  static async createSheet(idempotentId, portfolioId, sheet) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/sheet`, sheet);
  }

  static async getPopularProviders(idempotentId, providerType, category) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/popularInstitution/${category}`, { params: { type: providerType } });
  }

  static async getJwtToken(idempotentId, linkType, category, action, providerId, providerName) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(
      `/auth/link/${linkType}/user/jwttoken/category/${category}/action/${action}/providerId/${providerId}/providerName/${encodeURIComponent(
        providerName
      )}`
    );
  }

  static async getPublicTokenForCustodian(idempotentId, linkType, custodianId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/${linkType}/publicToken/custodianId/${encodeURIComponent(custodianId)}`);
  }

  static async getLinkTokenForCustodian(idempotentId, linkType, linkDetails) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/link/${linkType}/linkToken`, linkDetails);
  }

  static async setPublicTokenForCustodian(idempotentId, linkType, custodianId, params) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/link/${linkType}/publicToken/custodianId/${encodeURIComponent(custodianId)}`,
      params
    );
  }

  static async getLinkedAccountList(idempotentId, linkType, category, params) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/link/${linkType}/accountList/category/${category}`, params);
  }

  static async getLinkedConnectionsList(idempotentId, groupId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/connectionList/groupId/${groupId}`);
  }

  static async getLinkConnectionUrl(idempotentId, linkType, linkProviderId, params) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/${linkType}/connectUrl/providerId/${linkProviderId}`, {
      params: params
    });
  }

  static async getRefreshConnectionUrl(idempotentId, linkType, linkProviderAccountId, params) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/${linkType}/refreshUrl/providerAccountId/${linkProviderAccountId}`, {
      params: params
    });
  }

  static async getReconnectConnectionUrl(idempotentId, linkType, linkProviderAccountId, params) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/${linkType}/reconnectUrl/providerAccountId/${linkProviderAccountId}`, {
      params: params
    });
  }

  static async searchProviders(
    idempotentId,
    providerType,
    category,
    searchText,
    aggregatorToBeSkipped,
    linkProviderIdToBeSkipped
  ) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/popularInstitution/${category}/search/${encodeURIComponent(searchText)}`, {
      params: {
        type: providerType,
        skipAggregator: aggregatorToBeSkipped,
        skipLinkProviderId: linkProviderIdToBeSkipped
      }
    });
  }

  static async getProviderDetails(idempotentId, linkType, providerId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/${linkType}/institution/${providerId}`);
  }

  static async uploadPortfolioFolder(idempotentId, portfolioId, file) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/document/portfolio/${portfolioId}/folderCreate`, {
      id: idempotentId,
      name: file.name
    });
  }

  static async uploadPortfolioDocument(idempotentId, portfolioId, file, thumbnailFile, id, onUploadProgress) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("fileType", file.type);
    formData.append("document", file);
    if (thumbnailFile) {
      formData.append("thumbnailDocument", thumbnailFile);
    }
    return requestInstance.post(`/auth/document/portfolio/${portfolioId}`, formData, {
      onUploadProgress: onUploadProgress
    });
  }

  static async uploadPortfolioDocumentToFolder(
    idempotentId,
    portfolioId,
    folderId,
    file,
    thumbnailFile,
    id,
    onUploadProgress
  ) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("fileType", file.type);
    formData.append("document", file);
    if (thumbnailFile) {
      formData.append("thumbnailDocument", thumbnailFile);
    }
    return requestInstance.post(
      `/auth/document/portfolio/${portfolioId}/folder/${encodeURIComponent(folderId)}`,
      formData,
      {
        onUploadProgress: onUploadProgress
      }
    );
  }

  static async uploadCustodianDocument(
    idempotentId,
    portfolioId,
    file,
    thumbnailFile,
    id,
    onUploadProgress,
    custodianId
  ) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("fileType", file.type);
    formData.append("document", file);
    if (thumbnailFile) {
      formData.append("thumbnailDocument", thumbnailFile);
    }
    return requestInstance.post(
      `/auth/document/portfolio/${portfolioId}/custodian/${encodeURIComponent(custodianId)}`,
      formData,
      {
        onUploadProgress: onUploadProgress
      }
    );
  }

  static async getCustomHostFileDownloadUrl(idempotentId, token, inline, filename, filetype) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/document/fileUrl/${token}`, {
      params: { inline: inline, name: filename, type: filetype }
    });
  }

  static getFileDownloadUrl(doc, inline, thumbnail) {
    return new Promise((resolve, reject) => {
      let token = doc.documentToken;
      if (thumbnail === true) {
        token = doc.thumbnailToken;
      }
      token = encodeURIComponent(token);

      const isInline = inline === true ? 1 : 0;

      let filename = "";
      if (doc.name) {
        filename = doc.name;
      }

      let filetype = "";
      if (doc.fileType) {
        filetype = doc.fileType;
      }

      if (isCustomWhiteLabelHost() === true) {
        ApiClient.getCustomHostFileDownloadUrl(getUuid(), token, isInline, filename, filetype)
          .then(apiResponse => {
            resolve(apiResponse.payload.url);
          })
          .catch(apiError => {
            reject(apiError);
          });
      } else {
        var url =
          process.env.REACT_APP_FILES_BASE_URL +
          `/auth/file/${token}?name=${encodeURIComponent(filename)}&type=${encodeURIComponent(
            filetype
          )}&inline=${isInline}`;

        if (isAppInViewMode()) {
          url = url.replace("/auth/", "/view/");
        }

        resolve(url);
      }
    });
  }

  static async renameDocument(idempotentId, documentId, name) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/document/${documentId}`, { name: name });
  }

  static async renameFolder(idempotentId, documentId, name) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/document/folder/${documentId}`, { name: name });
  }

  static async downloadFolder(documentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(getUuid());
    return requestInstance.get(`/auth/document/folder/${documentId}/export`);
  }

  static async deleteDocument(idempotentId, documentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/document/${documentId}`);
  }

  static async deleteDocumentsFolder(idempotentId, documentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/document/folder/${documentId}`);
  }

  static async deleteDocumentsCustodian(idempotentId, documentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/document/custodian/${documentId}`);
  }

  static async getBeneficiaries(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/user/beneficiary");
  }

  static async updateBeneficiaries(idempotentId, beneficiaries) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/beneficiary", beneficiaries);
  }

  static async sendTestMail(idempotentId, portfolioId, type) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/settings/beneficiary/${type}/sendTestMail`);
  }

  static async getArchivedPortfolio(idempotentId, portfolioId, category) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/archive/${portfolioId}/category/${category}`);
  }

  static async getPortfolioDownloadUrl(portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(getUuid());
    return requestInstance.get(`/auth/portfolio/${portfolioId}/export/dataUrl`);
  }

  static async getPortfolioDownloadDataUrl(portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(getUuid());
    return requestInstance.get(`/auth/portfolio/${portfolioId}/export/dataJsonArchiveUrl`);
  }

  static async getLllmDataDownloadUrl(portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(getUuid());
    return requestInstance.get(`/auth/portfolio/${portfolioId}/export/llmDataUrl`);
  }

  static async getWlClientDataArchiveUrl(idempotentId, userId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(
      idempotentId,
      undefined,
      undefined,
      false,
      config => {
        config.headers["x-wl-user-id"] = userId;
      }
    );
    return requestInstance.get(`/auth/portfolio/${portfolioId}/export/dataJsonArchiveUrl`);
  }

  static getWlClientDataDownloadUrl(userId, portfolioId) {
    return new Promise((resolve, reject) => {
      ApiClient.getWlClientDataArchiveUrl(getUuid(), userId, portfolioId)
        .then(apiResponse => {
          resolve(apiResponse.payload.url);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  }

  static async getCustomHostSheetDownloadUrl(idempotentId, portfolioId, sheetId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/${portfolioId}/sheet/${sheetId}/exportUrl`);
  }

  static getSheetDownloadUrl(portfolioId, sheetId) {
    return new Promise((resolve, reject) => {
      if (isCustomWhiteLabelHost() === true || isSharedPortfolioUserSelector(store.getState())) {
        ApiClient.getCustomHostSheetDownloadUrl(getUuid(), portfolioId, sheetId)
          .then(apiResponse => {
            resolve(apiResponse.payload.url);
          })
          .catch(apiError => {
            reject(apiError);
          });
      } else {
        var downloadUrl = process.env.REACT_APP_API_BASE_URL + `/auth/portfolio/${portfolioId}/sheet/${sheetId}/export`;
        const wlClientContext = wlClientContextSelector(store.getState());

        if (isAppInViewMode()) {
          downloadUrl = downloadUrl.replace("/auth/", "/view/");
        }
        if (!wlClientContext === false) {
          downloadUrl = downloadUrl.replace("/auth/", "/admin/auth/");
          downloadUrl += `?kuberaWLUserId=${wlClientContext.userId}`;
        }

        resolve(downloadUrl);
      }
    });
  }

  static async exportPortfolio(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/${portfolioId}/export`);
  }

  static async exportPortfolioSafeDeposit(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/${portfolioId}/export/document`);
  }

  static async exportCustodianDocuments(idempotentId, sectionId, custodianId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/export/document`
    );
  }

  static async getUserPreferences(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/user/preference");
  }

  static async setUserPreferences(idempotentId, userPreferences) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/preference", userPreferences);
  }

  static async uploadProfilePhoto(idempotentId, file) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("fileType", file.type);
    formData.append("photo", file);
    return requestInstance.post("/auth/document/photo", formData);
  }

  static async getCustomHostProfilePictureDownloadUrl(idempotentId, token, inline, filename) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/document/photoUrl/${encodeURIComponent(token)}`);
  }

  static getProfilePictureDownloadUrl(token) {
    return new Promise((resolve, reject) => {
      if (isCustomWhiteLabelHost() === true) {
        ApiClient.getCustomHostProfilePictureDownloadUrl(getUuid(), token)
          .then(apiResponse => {
            resolve(apiResponse.payload.url);
          })
          .catch(apiError => {
            reject(apiError);
          });
      } else {
        const url = process.env.REACT_APP_FILES_BASE_URL + `/auth/photo/${encodeURIComponent(token)}`;
        resolve(url);
      }
    });
  }

  static async requestAccountDeletion(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/delete/request");
  }

  static async deleteUserAccount(idempotentId, token) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/public/user/delete", { params: { token: token } });
  }

  static async markUserActive(idempotentId, token) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/public/user/active", { params: { token: token } });
  }

  static async requestUserPhoneUpdateOtp(idempotentId, phone) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/phone/update/request", { phone: phone });
  }

  static async submitUserPhoneUpdateOtp(idempotentId, phone, otp) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/phone/update", { phone: phone, otp: otp });
  }

  static async removeUserPhoneNumber(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/phone/remove");
  }

  static async deleteProviderAccount(idempotentId, linkType, providerAccountId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/link/${linkType}/delete/providerAccount/${providerAccountId}`);
  }

  static async subscribeUserEmail(idempotentId, email, source) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId, false, true);
    return requestInstance.post(`/public/user/subscribe/email/${email}/source/${source}`);
  }

  static async getClientInfo(idempotentId, isBeta = 0) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/public/clientinfo/web", { params: { beta: isBeta } });
  }

  static async startTrial(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/startTrial", body);
  }

  static async getTrialInfo(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/subscription/trial");
  }

  static async createSubscription(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/create", body);
  }

  static async upgradeSubscription(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/upgrade", body);
  }

  static async cancelSubscription(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/cancel");
  }

  static async detachSubscriptionCard(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/detachPaymentMethod");
  }

  static async updateSubscriptionCard(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/attachPaymentMethod", body);
  }

  static async updateUnverifiedSubscriptionCard(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/storeUnverifiedPaymentMethod", body);
  }

  static async retrySubscription(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/retry", body);
  }

  static async setupPaymentIntent(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/setupPaymentIntent", body);
  }

  static async getSubscriptionState(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/subscription/get");
  }

  static async exportAndDelete(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/exportAndDelete/request");
  }

  static async generateBackupCodes(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/user/mfa/generateBackupCode");
  }

  static async userPhoneSet(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/phone/set", body);
  }

  static async verifyUserBackupCode(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/public/user/mfa/verifyUserBackupCode", body);
  }

  static async userSetMFA(idempotentId, type) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/user/mfa/type/${type}`);
  }

  static async getNetWorthData(idempotentId, portfolioId, includeDetail = 1) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/${portfolioId}/netWorth/includeDetail/${includeDetail}`);
  }

  static async getReportsData(idempotentId, portfolioId, currency = undefined, isMobileDevice = false) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    var params = {};
    if (!currency === false) {
      params.currency = currency;
    }
    if (isMobileDevice) {
      return requestInstance.get(`/auth/portfolio/${portfolioId}/custodiansRecap?excludeDetails=1`, { params: params });
    }
    return requestInstance.get(`/auth/portfolio/${portfolioId}/custodiansRecap`, { params: params });
  }

  static async searchTickerInfo(idempotentId, term, type) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/utility/searchTickerInfo?term=${encodeURIComponent(term)}&type=${type}`);
  }

  static async searchHomesInfo(idempotentId, term, custodianId, type) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);

    let suffixStr = "";
    if (type) {
      suffixStr += `&type=${type}`;
    }

    return requestInstance.get(
      `/auth/utility/searchAddress?term=${encodeURIComponent(term)}&token=${encodeURIComponent(
        custodianId
      )}${suffixStr}`
    );
  }

  static async linkAndGetAddressDetails(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/link/6/accountList/category/Asset", body);
  }

  static async getCustodianChart(idempotentId, custodianId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/section/custodianChart/${encodeURIComponent(custodianId)}`);
  }

  static async getVehicleDetails(idempotentId, vinNumber) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/utility/searchVehicle?vin=${encodeURIComponent(vinNumber)}`);
  }

  static async linkAndGetVehicleDetails(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/link/8/accountList/category/Asset", body);
  }

  static async getDomainDetails(idempotentId, vinNumber) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/utility/searchDomain?term=${encodeURIComponent(vinNumber)}`);
  }

  static async linkAndGetDomainDetails(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/link/7/accountList/category/Asset", body);
  }

  static async getWlSiteConfig(idempotentId, whiteLabelId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/public/wl/${encodeURIComponent(whiteLabelId)}/config`);
  }

  static async getWlDashboardMetaInfo(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/admin/dashboard/meta");
  }

  static async getWlDashboardUpdatedInfo(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/admin/dashboard/detail");
  }

  static async createWlUser(idempotentId, user) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/auth/user/create", user);
  }

  static async inviteWlUser(idempotentId, user, inviteClient) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/create/invite/${inviteClient}`, user);
  }

  static async updateWlUserInvite(idempotentId, user, inviteClient) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/update/invite/${inviteClient}`, user);
  }

  static async createWlSubUser(idempotentId, user) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/auth/user/createOrUpdate/subUser/invite/0", user);
  }

  static async inviteWlSubUser(idempotentId, user) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/auth/user/createOrUpdate/subUser/invite/1", user);
  }

  static async deleteWlSubUser(idempotentId, user) {
    const { email } = user;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/email/${encodeURIComponent(email)}/delete`);
  }

  static async archiveWlUser(idempotentId, email) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/email/${encodeURIComponent(email)}/archive/1`);
  }

  static async unarchiveWlUser(idempotentId, email) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/email/${encodeURIComponent(email)}/archive/0`);
  }

  static async updateWlUserRole(idempotentId, email, role) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/email/${encodeURIComponent(email)}`, { type: role });
  }

  static async deleteWlUser(idempotentId, email) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/email/${encodeURIComponent(email)}/delete`);
  }

  static async getWlManagers(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/admin/auth/user/manager");
  }

  static async pingWlClientToUpdatePortfolio(idempotentId, userId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/auth/user/ping/${userId}`);
  }

  static async inviteWlClientAgain(idempotentId, client) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/auth/user/reInvite", client);
  }

  static async downloadWLClientData(idempotentId, client) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("admin/wl/export", client);
  }

  static async setUserCollaborateDocumentStatus(idempotentId, status) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/user/collaborateDocument/${status}`);
  }

  static async utilityStatus(idempotentId, body) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/utility/status", body);
  }

  static async updateCustodianBulk(idempotentId, custodians, source = axios.CancelToken.source()) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);

    const config = { cancelToken: source.token };
    return requestInstance.post("/auth/section/custodian/update", { custodian: custodians }, config);
  }

  static async getZerionAccountList(idempotentId, custodianId, data) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/custodian/${encodeURIComponent(custodianId)}/zerionAccountList`, data);
  }

  static async updateSectionBulk(idempotentId, sections) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/section/update", { section: sections });
  }

  static async validatePhone(idempotentId, phone) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/utility/validateNumber", { phone });
  }

  static async getViewOnlySiteConfig(idempotentId, token) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/public/view/${encodeURIComponent(token)}/config`);
  }

  static async verifyPasscode(idempotentId, passcode) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(
      idempotentId,
      undefined,
      undefined,
      false,
      config => {
        config.headers["x-view-passcode"] = passcode;
      }
    );
    return requestInstance.get("/view/portfolio/verify");
  }

  static async verifyEmail(idempotentId, name, email) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/email/update/request", { name, email });
  }

  static async validateEmail(idempotentId, name, email, verificationCode) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/updateNameAndEmail", { name, email, verificationCode });
  }

  static async applyPromoCode(idempotentId, promoCode) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/subscription/applyPromotionCode", { promotionCode: promoCode });
  }

  static async getCreditBalance(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/subscription/getAccountBalance");
  }

  static async getIpInfo(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/public/ipInfoV2");
  }

  static async logOutFromAllDevices(idempotentId, name, email) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/resetAllSession", { name, email });
  }

  static async createCustodianOwnership(idempotentId, sectionId, custodianId, ownership) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/ownership`,
      ownership
    );
  }

  static async updateCustodianOwnership(idempotentId, sectionId, custodianId, ownershipId, ownership) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/ownership/${ownershipId}`,
      ownership
    );
  }

  static async deleteCustodianOwnership(idempotentId, sectionId, custodianId, ownershipId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/ownership/${ownershipId}`
    );
  }

  static async createCustodianCashflow(idempotentId, custodianId, cashflow) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/custodian/${encodeURIComponent(custodianId)}/cashFlow`, cashflow);
  }

  static async updateCustodianCashflow(idempotentId, custodianId, cashflowId, cashflow) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/custodian/${encodeURIComponent(custodianId)}/cashFlow/${cashflowId}`,
      cashflow
    );
  }

  static async deleteCustodianCashflow(idempotentId, custodianId, cashflowId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/section/custodian/${encodeURIComponent(custodianId)}/cashFlow/${cashflowId}`);
  }

  static async deleteAllCustodianCashflows(idempotentId, custodianId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/section/custodian/${encodeURIComponent(custodianId)}/cashFlow/all`);
  }

  static async updateCustodianIrrType(idempotentId, custodianId, irrType) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/section/custodian/${encodeURIComponent(custodianId)}/irrType/${irrType}`);
  }

  static async getUpdatedIrr(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/irrUpdatedV2/${portfolioId}`);
  }

  static async updateSheetIrr(idempotentId, portfolioTickerId, sheetIds) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/portfolio/sheet/irrUpdate", {
      portfolioTickerId,
      sheetIds
    });
  }

  static async updateSectionIrr(idempotentId, portfolioTickerId, sectionIds) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/section/irrUpdate", {
      portfolioTickerId,
      sectionIds
    });
  }

  static async getSheetIrr(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/sheet/irrUpdate?portfolioId=${portfolioId}`);
  }

  static async getSectionIrr(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/section/irrUpdate?portfolioId=${portfolioId}`);
  }

  static async getPortfolioChangeData(idempotentId, portfolioId, type, size = 100, startDateTs) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(
      `/auth/portfolio/${portfolioId}/change/size/${size}/type/${type}/tsStartDate/${startDateTs}`
    );
  }

  static async setManagerForClient(idempotentId, clientId, managerId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/user/manager/${managerId}/user/${clientId}`);
  }

  static async getAppStatus(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/public/status");
  }

  static async getConnectionInfo(idempotentId, linkType, providerId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/${linkType}/connectInfo/providerId/${providerId}`);
  }

  static async getReConnectionInfo(idempotentId, linkType, providerAccountId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/link/${linkType}/reconnectInfo/providerAccountId/${providerAccountId}`);
  }

  static async verifyUser(idempotentId, code) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/utility/verify", { code: code });
  }

  static async setLinkingProviderStatus(idempotentId, linkType, groupId, providerId, status) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/link/${linkType}/groupId/${groupId}/institution/${providerId}/status/${status}`);
  }

  static async getMultiuserList(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    if (isSharedPortfolioUserSelector(store.getState())) {
      return requestInstance.get("/family/auth/user/member");
    } else {
      return requestInstance.get("/auth/user/family/member");
    }
  }

  static async sendMultiuserInvite(idempotentId, name, email, access) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/family/member/invite", { name, email, ...access });
  }

  static async updateMultiuserInvite(idempotentId, invitationId, dataToUpdate) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/user/family/member/updateInvite/${invitationId}`, dataToUpdate);
  }

  static async verifyMultiuserInvitationId(idempotentId, email, invitationId, recaptchaToken) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/public/invitation/verify", { emailId: email, invitationId, recaptchaToken });
  }

  static async sendMultiuserReInvite(idempotentId, multiuserId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/user/family/member/reinvite/${multiuserId}`);
  }

  static async acceptMultiuserInvite(idempotentId, invitationId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/user/family/member/accept/${invitationId}`);
  }

  static async deleteSubAccountuser(idempotentId, multiuserId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/user/family/member/delete/${multiuserId}`);
  }

  static async subAccountUserSelfLeave(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/family/auth/user/member/leave${!portfolioId === false ? `/${portfolioId}` : ""}`);
  }

  static async getCustomHostCashFlowDownloadUrl(idempotentId, sectionId, custodianId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(
      `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/export/cashFlowUrl`
    );
  }

  static async CapturePageLoad(payload) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(getUuid());
    return requestInstance.post("/auth/utility/analytics", payload);
  }

  static async getConnectivityCenterData() {
    const requestInstance = await ApiClient.getAuthenticatedInstance(getUuid());
    return requestInstance.get("/auth/portfolio/connectivityCenter");
  }

  static getCashFlowDownloadUrl(sectionId, custodianId) {
    return new Promise((resolve, reject) => {
      if (isCustomWhiteLabelHost() === true || isSharedPortfolioUserSelector(store.getState())) {
        ApiClient.getCustomHostCashFlowDownloadUrl(getUuid(), sectionId, custodianId)
          .then(apiResponse => {
            resolve(apiResponse.payload.url);
          })
          .catch(apiError => {
            reject(apiError);
          });
      } else {
        var downloadUrl =
          process.env.REACT_APP_API_BASE_URL +
          `/auth/section/${sectionId}/custodian/${encodeURIComponent(custodianId)}/export/cashFlow`;

        const wlClientContext = wlClientContextSelector(store.getState());

        if (isAppInViewMode()) {
          downloadUrl = downloadUrl.replace("/auth/", "/view/");
        }
        if (!wlClientContext === false) {
          downloadUrl = downloadUrl.replace("/auth/", "/admin/auth/");
          downloadUrl += `?kuberaWLUserId=${wlClientContext.userId}`;
        }

        resolve(downloadUrl);
      }
    });
  }

  static async getCustomHostCustodianSubDetailsDownloadUrl(idempotentId, downloadUrl) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(downloadUrl);
  }

  static getCustodianSubDetailsDownloadUrl(custodianId, detailsType, format) {
    return new Promise((resolve, reject) => {
      var downloadUrl = `/auth/section/custodian/${encodeURIComponent(
        custodianId
      )}/exportURL/${detailsType}/format/${encodeURIComponent(format)}`;

      if (isCustomWhiteLabelHost() === false) {
        const wlClientContext = wlClientContextSelector(store.getState());

        if (isAppInViewMode()) {
          downloadUrl = downloadUrl.replace("/auth/", "/view/");
        }
        if (!wlClientContext === false) {
          downloadUrl = downloadUrl.replace("/auth/", "/admin/auth/");
          downloadUrl += `?kuberaWLUserId=${wlClientContext.userId}`;
        }
      }

      ApiClient.getCustomHostCustodianSubDetailsDownloadUrl(getUuid(), downloadUrl)
        .then(apiResponse => {
          resolve(apiResponse.payload.url);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  }

  static async connectStripe(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/payment/connectAccount", {
      test: 0
    });
  }

  static async saveConnectedAccount(idempotentId, data) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/payment/saveConnectedAccount", data);
  }

  static async removeConnectedAccount(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/payment/removeConnectedAccount");
  }

  static async adminChargeClient(idempotentId, data) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/payment/charge", data);
  }

  static async adminRemoveChargeClient(idempotentId, chargeId, userId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/admin/payment/charge/${chargeId}/client/${userId}`);
  }

  static async adminScheduleClient(idempotentId, data) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/payment/recurring", data);
  }

  static async adminDeleteScheduleClient(idempotentId, userId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`admin/payment/recurring/${userId}`);
  }

  static async getAdminListCharges(idempotentId, listId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/admin/payment/list/${listId}`);
  }

  static async getClientListCharges(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/payment/list");
  }

  static async adminAddPaymentMethodInStripe(idempotentId, data) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/admin/payment/dashboardUrl", data);
  }

  static async adminRetryChargeClient(idempotentId, chargeId, userId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/admin/payment/retryCharge/${chargeId}/client/${userId}`);
  }

  static async clientRetryChargeClient(idempotentId, chargeId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/payment/retryCharge/${chargeId}`);
  }

  static async getClientDashboardData(idempotentId, userId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/admin/user/client/${userId}`);
  }

  static async getReceipts(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/subscription/payments");
  }

  static async getReceiptUrl(idempotentId, invoiceId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/subscription/invoiceUrl/${invoiceId}`);
  }

  static async getLongEquityData(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/${portfolioId}/pluginInfo`);
  }

  static async savePluginDataForPluginId(idempotentId, portfolioId, pluginId, data = {}) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/plugin/${pluginId}`, data);
  }

  static async uploadWLKCSVDocument(idempotentId, file, id, isImportFlow) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("fileType", file.type);
    formData.append("csv", file);
    return requestInstance.post(`/admin/import/cashFlow/${isImportFlow ? "insert" : "verify"}`, formData);
  }

  static async uploadWLKHistoryDocument(idempotentId, file, id, isImportFlow) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("fileType", file.type);
    formData.append("csv", file);
    return requestInstance.post(`/admin/import/history/${isImportFlow ? "insert" : "verify"}`, formData);
  }

  static async uploadWLKAssetsDebtsDocument(idempotentId, file, id, isImportFlow) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("fileType", file.type);
    formData.append("csv", file);
    return requestInstance.post(`/admin/import/custodian/${isImportFlow ? "insert" : "verify"}`, formData);
  }

  static async uploadWLKCurrentValueDocument(idempotentId, file, id, isImportFlow) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("fileType", file.type);
    formData.append("csv", file);
    return requestInstance.post(`/admin/import/custodianUpdate/${isImportFlow ? "insert" : "verify"}`, formData);
  }

  static async signUp(idempotentId, payload) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/public/signup", payload);
  }

  static async signupVerify(idempotentId, payload) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/public/signupVerify", payload);
  }

  static async userSetPasswordUpdated(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/setPasswordUpdated");
  }

  static async createPlanningScenario(idempotentId, portfolioId, scenario) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/scenario`, scenario);
  }

  static async updatePlanningScenario(idempotentId, portfolioId, scenario) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/scenario/${scenario.id}`, scenario);
  }

  static updatePlanningScenarioBulk(idempotentId, portfolioId, scenarios) {
    const requestInstance = ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/scenario/update`, { scenario: scenarios });
  }

  static async deletePlanningScenario(idempotentId, portfolioId, scenarioId) {
    const url = `/auth/portfolio/${portfolioId}/scenario/${scenarioId}`;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.request({ method: "purge", url: url });
  }

  static async createPlanningRule(idempotentId, scenarioId, rule) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/scenario/${scenarioId}/rule`, {
      ...rule,
      data: JSON.stringify(rule.data)
    });
  }

  static async updatePlanningRule(idempotentId, scenarioId, ruleId, dateToBeUpdated) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/scenario/${scenarioId}/rule/${ruleId}`, dateToBeUpdated);
  }

  static async updatePlanningRuleBulk(idempotentId, scenarioId, rules) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/scenario/${scenarioId}/rule/update`, { rule: rules });
  }

  static async deletePlanningRule(idempotentId, rule) {
    const url = `/auth/portfolio/scenario/${rule.scenarioId}/rule/${rule.id}`;
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.request({ method: "purge", url: url });
  }

  static async updateReportPreferences(idempotentId, portfolioId, reportType, preferences) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/reportPreference/${reportType}`, preferences);
  }

  static async deleteReportPreferences(idempotentId, portfolioId, reportType) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/portfolio/${portfolioId}/reportPreference/${reportType}`);
  }

  static async updateDiyCharts(idempotentId, portfolioId, charts) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/portfolio/${portfolioId}/diyChart`, {
      ...charts,
      data: JSON.stringify(charts.data)
    });
  }

  static async deleteDiyCharts(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/portfolio/${portfolioId}/diyChart`);
  }

  static async createCustodianRate(idempotentId, sectionId, custodianId, uuid, data) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    data.id = uuid;
    return requestInstance.post(`/auth/section/${sectionId}/custodian/${custodianId}/rate`, data);
  }

  static async updateCustodianRate(idempotentId, sectionId, custodianId, uuid, data) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    data.id = uuid;
    return requestInstance.post(`/auth/section/${sectionId}/custodian/${custodianId}/rate/${uuid}`, data);
  }

  static async deleteCustodianRate(idempotentId, sectionId, custodianId, uuid) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(`/auth/section/${sectionId}/custodian/${custodianId}/rate/${uuid}`);
  }

  static async optInForBlackTrial(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/optin/trial/black");
  }

  static async getUniqueSessionId(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/utility/uniqueSessionId");
  }

  static async getUserAPIKeys(idempotentId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get("/auth/user/apiKey");
  }

  static async createUserAPIKey(idempotentId, payload) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/auth/user/apiKey/add", payload);
  }

  static async deleteUserAPIKey(idempotentId, key) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(`/auth/user/apiKey/delete/${key}`);
  }

  static async verifyV3Token(idempotentId, token) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post("/public/siteVerify", { recaptchaToken: token });
  }

  static async createCustodianFundSchedule(idempotentId, custodianId, fundSchedule) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/custodian/${encodeURIComponent(custodianId)}/fundSchedule`,
      fundSchedule
    );
  }

  static async updateCustodianFundSchedule(idempotentId, custodianId, fundScheduleId, fundSchedule) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.post(
      `/auth/section/custodian/${encodeURIComponent(custodianId)}/fundSchedule/${fundScheduleId}`,
      fundSchedule
    );
  }

  static async deleteCustodianFundSchedule(idempotentId, custodianId, fundScheduleId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.delete(
      `/auth/section/custodian/${encodeURIComponent(custodianId)}/fundSchedule/${fundScheduleId}`
    );
  }

  static async getPortfolioFundSchedule(idempotentId, portfolioId) {
    const requestInstance = await ApiClient.getAuthenticatedInstance(idempotentId);
    return requestInstance.get(`/auth/portfolio/${portfolioId}/fundSchedule`);
  }
}

import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  searchHomesInfo,
  currentPortfolioSelector,
  linkAndGetAddressDetails,
  getCurrentCustodianFromCustodianId,
  insertTickerCustodianInSection,
  refreshCustodian,
  formatNumberWithCurrency,
  showToastTip,
  dismissToastAction,
  accountLinkingService,
  getExchangeRate,
  getUuid,
  insertCustodianAtEndOfSection,
  updateCustodian,
  getTickerUsingShortName,
  getTickerUsingId,
  insertSheet,
  utilityStatus,
  getAccountLinkingService,
  tickerSubTypes,
  getExchangeRateDetails,
  userCountryCodeSelector,
  getSortKeyBetween,
  useHistory,
  getTickersForIds,
  UNKNOWN_TICKER_SHORT_NAME,
  updateDashboardAction
} from "@kubera/common";

import { getEmptySheet, filterDataFromPortfolioDetails } from "components/grid/GridDataModel";
import requestIdleCallback from "utilities/requestIdleCallback";
import AutocompleteSearchInp from "components/inputs/AutocompleteSearchInp";
import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import { category } from "components/dashboard/DashboardComponentExports";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

import EditableTicker from "./EditableTicker";

const Container = styled.div`
  height: 100%;
  padding-bottom: 61px;
  box-sizing: border-box;
`;

const SearchInpWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  z-index: 2;
`;

const SearchListItem = styled.li`
  display: ${props => (!props.isHidden ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  padding: 16px 13px 18px;
  cursor: pointer;
  pointer-events: ${props => (props.isNotFound ? "none" : null)};
  height: ${props => (props.isNotFound ? "50px" : null)};
  box-sizing: border-box;

  &.is-active {
    background: ${props => (props.isNotFound ? "#ffffff" : props.theme.contextMenuItemSelectedBackgroundColor)};
  }
`;

const SearchListHead = styled.div`
  font-size: 14px;
  line-height: 130%;
  text-transform: ${props => (!props.isNotFound ? "capitalize" : null)};
`;

const SearchListAddress = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
`;

const TickersForm = styled.form`
  position: relative;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: 1;
  }
`;

const TickerFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const SearchMoreTxt = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  margin-top: 2px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.errorCLR};
  margin-bottom: 16px;
`;

const DoneBtn = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
  margin-bottom: 10px;
`;

const InputContainer = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  height: 35px;
  position: relative;
`;

const InputAutocompleteSearchInp = styled(AutocompleteSearchInp)`
  padding-right: 91px;
  box-sizing: border-box;
`;

const AddBtn = styled(PrimaryButton)`
  position: absolute;
  right: 0;
  top: 0;
  width: 91px;
  min-width: 91px;
  height: 35px;
  font-size: 10px;
`;

const FooterTxtBottom = styled.div`
  position: absolute;
  bottom: 5px;
  left: 0;
`;

const FooterTxtTitle = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const FooterBody = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  white-space: pre-line;
`;

const HomesConnectComponent = props => {
  const { searchInputPlaceholder = "" } = props;

  const countryCode = useSelector(userCountryCodeSelector);
  const portfolio = useSelector(currentPortfolioSelector);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchTimeoutId = useRef(null);
  const formRef = useRef();
  const searchInpRef = useRef();

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // eslint-disable-line
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isValueLoading, setIsValueLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [currentCustodian, setCurrentCustodian] = useState(null);
  const [tickersWithNoValue, setTickersWithNoValue] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const custodian = getCurrentCustodianFromCustodianId(props.custodianId);

  const splitAddress = address => {
    return address ? address.split(/,(.+)/) : ["", ""];
  };

  const getSearchInput = () => {
    if (searchInpRef.current && searchInpRef.current.querySelector("input")) {
      return searchInpRef.current.querySelector("input");
    }

    return {
      onFocus: () => null,
      onBlur: () => null
    };
  };

  const getSearchTickerInfo = async value => {
    const response = await dispatch(
      searchHomesInfo(value, props.custodianId, `country:${countryCode.toLowerCase()}`)
    ).catch(err => {
      setIsSearchLoading(false);
    });

    const { payload: results } = response || {};

    if (value && (!results || results.length === 0)) {
      setSearchResults([
        {
          placeId: "0",
          address: "Nothing found",
          nf: true
        }
      ]);
    } else {
      setSearchResults(results || []);
    }
    setIsSearchLoading(false);
  };

  const onSearchChange = value => {
    setSearchText(value);
    setSearchResults([]);

    if (value && value.length < 6) {
      if (props.isEditMode) {
        setSearchResults([
          {
            address: searchText,
            placeId: getUuid(),
            isHidden: true
          }
        ]);
      }
      return;
    }
    if (value) {
      setIsSearchLoading(true);
    } else {
      setIsSearchLoading(false);
      clearTimeout(searchTimeoutId.current);
      return;
    }

    clearTimeout(searchTimeoutId.current);
    searchTimeoutId.current = setTimeout(() => {
      getSearchTickerInfo(value);
    }, 300);
  };

  const onRenderList = item => {
    if (searchText && isSearchFocused) {
      return (
        <SearchListItem key={item.placeId || item.zPId} isNotFound={item.nf} isHidden={item.isHidden}>
          <SearchListHead isNotFound={item.nf}>
            <SearchListAddress>{item.address}</SearchListAddress>
          </SearchListHead>
        </SearchListItem>
      );
    }

    return null;
  };

  const onInputEnter = e => {
    e.preventDefault();

    if (!searchText) {
      return;
    }

    const selected = {
      address: searchText,
      placeId: getUuid()
    };

    const tempSelectedTickers = [...selectedTickers];
    setSelectedTickers([
      ...tempSelectedTickers,
      {
        ...selected,
        tickerBalanceFound: true,
        isEditMode: true
      }
    ]);

    setSearchText("");
    getSearchInput().blur();
  };

  const onSelect = async (selected = searchResults[0]) => {
    const tempSelectedTickers = [...selectedTickers];

    if (!selected) {
      return;
    }

    if (selected && !selected.nf) {
      requestIdleCallback(() => {
        setSelectedTickers([
          ...tempSelectedTickers,
          {
            ...selected,
            tickerBalanceFound: true
          }
        ]);
      });

      setIsValueLoading(true);
      const info = await dispatch(linkAndGetAddressDetails(portfolio.id, selected));

      addToTickers(tempSelectedTickers, selected, info);
    } else if (searchResults && searchResults.length >= 1 && /^https:\/\//.test(searchText)) {
      const selected = searchResults[0];
      const info = await dispatch(linkAndGetAddressDetails(portfolio.id, selected));

      addToTickers(tempSelectedTickers, selected, info);
    } else if (searchText && !isSearchLoading) {
      const selected = { address: searchText };
      const info = await dispatch(linkAndGetAddressDetails(portfolio.id, selected));

      addToTickers(tempSelectedTickers, selected, info);
    }
    setIsValueLoading(false);
    setSearchResults([]);
    getSearchInput().blur();
  };

  const getRenderText = (item, ticker) => {
    const portfolioValueTickerId = getTickerUsingShortName(ticker.currency).id;

    const debtLinkedRow = item || {
      value: 0,
      valueTickerId: portfolioValueTickerId
    };

    const debtExchangeRate = getExchangeRate(
      getTickerUsingId(debtLinkedRow.valueTickerId || portfolioValueTickerId).shortName,
      ticker.currency
    );

    return `${formatNumberWithCurrency(item.value * debtExchangeRate, ticker.currency)} (${item.name})`;
  };

  const onMortgageSelect = (item, index, ticker) => {
    if (!item) {
      return;
    }

    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = getRenderText(item, ticker);
    tempSelectedTickers[index].relatedId = item.id;
    setSelectedTickers(tempSelectedTickers);
  };

  const addToTickers = async (selectedTickers, selected, info) => {
    if (!info || !info[0] || !info[0].balance) {
      setTickersWithNoValue([...tickersWithNoValue, selected]);
    }

    if (info && info.length > 0) {
      if (getTickerUsingId(info[0].tickerId).shortName === UNKNOWN_TICKER_SHORT_NAME) {
        await dispatch(
          getTickersForIds([info[0].tickerId], () => {
            dispatch(updateDashboardAction(null));
          })
        );
      }
      setSelectedTickers([
        ...selectedTickers,
        {
          ...selected,
          ...info[0],
          tickerBalanceFound: !!info[0].balance
        }
      ]);
    } else {
      setSelectedTickers([
        ...selectedTickers,
        {
          ...selected,
          tickerBalanceFound: false
        }
      ]);
    }

    setTimeout(() => {
      setSearchText("");
    }, 100);
  };

  const removeNoValTickerFromIndex = index => {
    const tempTickersWithNoValue = [...tickersWithNoValue];
    const matchingTickerIndex = tempTickersWithNoValue.findIndex(
      ticker => ticker.placeId === selectedTickers[index].placeId
    );
    tempTickersWithNoValue.splice(matchingTickerIndex, 1);
    setTickersWithNoValue(tempTickersWithNoValue);
  };

  const removeTickerFromIndex = index => {
    const tempSelectedTickers = [...selectedTickers];

    if (!tempSelectedTickers[index].tickerBalanceFound) {
      removeNoValTickerFromIndex(index);
    }

    tempSelectedTickers.splice(index, 1);
    setSelectedTickers(tempSelectedTickers);
  };

  const onTickerQuantityChange = (value, index, tickerDetails) => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = value;
    tempSelectedTickers[index].relatedId = null;
    tempSelectedTickers[index].tickerDetails = tickerDetails;
    setSelectedTickers(tempSelectedTickers);
  };

  const onRenderSelectedTicker = (ticker, index) => {
    return (
      <EditableTicker
        key={index}
        ticker={ticker}
        index={index}
        portfolio={portfolio}
        removeTickerFromIndex={removeTickerFromIndex}
        isValueLoading={isValueLoading}
        onTickerQuantityChange={onTickerQuantityChange}
        onMortgageSelect={onMortgageSelect}
        isEditMode={ticker.isEditMode}
        removeNoValTickerFromIndex={removeNoValTickerFromIndex}
      />
    );
  };

  const showToastForRowsWithNoValue = () => {
    if (tickersWithNoValue && tickersWithNoValue[0] && tickersWithNoValue.length > 0) {
      const splittedAddress = splitAddress(tickersWithNoValue[0].address);
      const pluralLength = tickersWithNoValue.length > 1 ? ` +${tickersWithNoValue.length - 1}` : ``;
      dispatch(dismissToastAction());
      dispatch(
        showToastTip(
          "TIP",
          `${i18n.t("homesConnect.couldntFindTip")} ‘${splittedAddress[0]}’${pluralLength}`,
          null,
          5000
        )
      );
    }
  };

  const onTickerSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    if (isValueLoading) {
      return;
    }

    setIsSubmitted(true);
    let custodianId = (props.nextValues && props.nextValues.custodianId) || currentCustodian.id;
    let sortKey = props.nextValues && props.nextValues.sortKey;
    let nextValues = void 0;

    if (formRef.current.checkValidity()) {
      selectedTickers.forEach(async ticker => {
        const splitTickerAddress = splitAddress(ticker.address);

        let relatedId = null;
        const tickerInputvalue = ticker.inputVal ? parseFloat(ticker.inputVal.replace(/[^\d.]/g, ""), 10) : 0;
        if (!ticker.relatedId && tickerInputvalue) {
          const debtSheets = filterDataFromPortfolioDetails(
            portfolio.details,
            row => !row.name && !row.value,
            void 0,
            sheet => sheet.category === category.DEBT
          );

          let debtLoanSection = filterDataFromPortfolioDetails(
            {
              sheet: debtSheets.sheets,
              section: debtSheets.sections,
              custodian: debtSheets.rows
            },
            row => !row.name && !row.value,
            void 0,
            sheet => sheet.name === "Loans"
          );

          if (debtLoanSection.sheets.length === 0) {
            const secondItemSortKey = getSortKeyBetween(
              debtSheets.sheets[0] ? debtSheets.sheets[0].sortKey : null,
              debtSheets.sheets[1] ? debtSheets.sheets[1].sortKey : null
            );
            const newSheet = getEmptySheet("Loans", secondItemSortKey, portfolio.currency, []);
            await dispatch(insertSheet(category.DEBT, newSheet));

            debtLoanSection = filterDataFromPortfolioDetails(
              portfolio.details,
              row => !row.name && !row.value,
              void 0,
              sheet => sheet.category === category.DEBT && sheet.name === "Loans"
            );
          }

          relatedId = (debtLoanSection.rows || []).length > 0 ? debtLoanSection.rows[0].id : getUuid();
          dispatch(insertCustodianAtEndOfSection(portfolio.id, debtLoanSection.sections[0].id, relatedId));

          const tickerId = getTickerUsingShortName(portfolio.currency).id;
          const portfolioValueTickerId = getTickerUsingShortName(ticker.currency).id;
          dispatch(
            updateCustodian(
              true,
              relatedId,
              {
                name: i18n.t("mortgage.debtTitle").replace("%s%", splitTickerAddress[0]),
                value: tickerInputvalue,
                valueTickerId: ticker.tickerDetails?.tickerId || tickerId,
                isCompleted: 1,
                valueExchangeRate:
                  ticker.tickerDetails?.exchangeRate ||
                  getExchangeRateDetails(
                    tickerId,
                    getExchangeRate(getTickerUsingId(tickerId || portfolioValueTickerId).shortName, portfolio.currency)
                  ),
                past: 0
              },
              true
            )
          );
        }

        const isConnected = ticker.accountName || ticker.url;

        const newCustodian = {
          linkType: isConnected ? accountLinkingService.ZILLOW : 0,
          name: splitTickerAddress[0],
          description: (splitTickerAddress[1] || "").trim(),
          value: ticker.balance ? ticker.balance : 0,
          valueTickerId: ticker.tickerId
            ? ticker.tickerId
            : isConnected
            ? 150
            : getTickerUsingShortName(portfolio.currency).id,
          valueExchangeRate: getExchangeRateDetails(
            ticker.tickerId,
            getExchangeRate(
              getTickerUsingId(ticker.tickerId || getTickerUsingShortName(portfolio.currency).id).shortName,
              portfolio.currency
            )
          ),
          linkProviderAccountId: ticker.providerAccountId || null,
          linkAccountId: ticker.id || null,
          linkAccountMask: "",
          linkAccountName: ticker.accountName || null,
          linkAccountContainer: isConnected ? "other" : null,
          linkProviderName: ticker.providerName || null,
          linkProviderId: ticker.providerName || null,
          relatedId: ticker.relatedId || relatedId,
          type: 1,
          url: ticker.url || null
        };

        if (ticker.isEditMode || !ticker.tickerBalanceFound) {
          newCustodian.linkType = 0;
          newCustodian.subType = tickerSubTypes.HOME;
        }
        nextValues = dispatch(
          insertTickerCustodianInSection(
            portfolio.id,
            currentCustodian.sectionId,
            custodianId,
            newCustodian,
            sortKey,
            custodian => {
              if (custodian.id) {
                dispatch(refreshCustodian(custodian.id, () => null, () => null, true, false));
              } else {
                dispatch(
                  utilityStatus({
                    errorMessage: "Failed to link custodian",
                    ...newCustodian,
                    linkType: getAccountLinkingService(
                      ticker.accountName && ticker.url ? accountLinkingService.ZILLOW : 0
                    )
                  })
                );
              }
            }
          )
        );

        custodianId = nextValues.custodianId;
        sortKey = nextValues.sortKey;
      });

      showToastForRowsWithNoValue();
      if (e) {
        DialogOverlay.forceDismiss(history, location);
      } else {
        props.setNextValues(nextValues);
      }
    } else {
      setErrorMessage(i18n.t(" "));
      props.onConfirmCancel();
    }
  };

  const onAddMoreClick = () => {
    getSearchInput().focus();
  };

  const onSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const onSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const saveConfirmModal = () => {
    onTickerSubmit();

    if (formRef.current && formRef.current.checkValidity()) {
      props.onConfirmSave();
    }
    setIsConfirmModalVisible(false);
  };

  const closeConfirmModal = () => {
    props.onConfirmCancel();
    setIsConfirmModalVisible(false);
  };

  useEffect(() => {
    if (custodian) {
      setCurrentCustodian(custodian);
    } else {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  useEffect(() => {
    setIsSubmitted(false);
    setErrorMessage("");
  }, [searchText, searchResults, selectedTickers]);

  const { clickedTab, homesSelectedTab, homesClickedTab, onConfirmSave } = props;
  useEffect(() => {
    if ((clickedTab !== 0 || homesSelectedTab !== homesClickedTab) && formRef.current) {
      setIsConfirmModalVisible(true);
    } else {
      onConfirmSave();
    }
  }, [clickedTab, homesSelectedTab, homesClickedTab, onConfirmSave]);

  if (currentCustodian) {
    return (
      <Container className={props.className}>
        <SearchInpWrapper ref={searchInpRef}>
          {!props.showAddButtonToEnterManually ? (
            <AutocompleteSearchInp
              inputPlaceholder={searchInputPlaceholder}
              searchValue={searchText}
              options={searchResults}
              onSearchChange={onSearchChange}
              onRenderList={onRenderList}
              onSelect={onSelect}
              isLoading={isSearchLoading}
              selectedIndex={0}
              onFocus={onSearchFocus}
              onBlur={onSearchBlur}
            />
          ) : (
            <InputContainer>
              <InputAutocompleteSearchInp
                inputPlaceholder={searchInputPlaceholder}
                searchValue={searchText}
                options={searchResults}
                onSearchChange={onSearchChange}
                onRenderList={onRenderList}
                onSelect={onSelect}
                selectedIndex={-1}
                onFocus={onSearchFocus}
                onBlur={onSearchBlur}
                isLoading={isSearchLoading}
              />
              <AddBtn title={i18n.t("add")} onClick={onInputEnter} isDisabled={!searchText} />
            </InputContainer>
          )}
        </SearchInpWrapper>
        {selectedTickers.length > 0 ? (
          <TickersForm
            ref={formRef}
            onSubmit={onTickerSubmit}
            disabled={isSearchFocused}
            noValidate
            data-exitconfirm={i18n.t("linkAccount.modalHomesTitle")}
          >
            {selectedTickers.map(onRenderSelectedTicker)}
            <TickerFormFooter>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <DoneBtn
                title={i18n.t("done")}
                data-cy="doneBtn"
                onClick={() => null}
                isDisabled={isValueLoading}
                tabIndex={0}
              />
            </TickerFormFooter>
            <SearchMoreTxt onClick={onAddMoreClick}>{i18n.t("linkAccount.addMoreTickers")}</SearchMoreTxt>
          </TickersForm>
        ) : (
          props.showSupportedFooter && (
            <FooterTxtBottom>
              <FooterTxtTitle>{i18n.t("linkAccount.homesFooterTitle")}</FooterTxtTitle>
              <FooterBody
                dangerouslySetInnerHTML={{
                  __html: i18n.t("linkAccount.homesFooterBody")
                }}
              />
            </FooterTxtBottom>
          )
        )}
        {isConfirmModalVisible && (
          <ConfirmationDialog
            title={i18n.t("linkAccount.saveAddTitle").replace("%s%", i18n.t("linkAccount.modalHomesTitle"))}
            description={i18n.t("linkAccount.saveStocksAddDesc").replace("%s%", i18n.t("linkAccount.modalHomesTitle"))}
            positiveButtonTitle={i18n.t("save")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={closeConfirmModal}
            handlePositiveButtonClick={saveConfirmModal}
          />
        )}
      </Container>
    );
  }

  return null;
};

export default HomesConnectComponent;

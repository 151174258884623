import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  currentPortfolioSelector,
  portfolioTotalForCategory,
  getChangeTotalsWithContributorsForCurrentPortfolio,
  pastValueInterval,
  updateUserPreferences,
  getSymbolForTickerUsingShortName
} from "@kubera/common";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import DashboardComponentExports, { category } from "components/dashboard/DashboardComponentExports";
import ChangeLabel from "components/labels/ChangeLabel";
import PortfolioTotalChangeDialog from "components/portfolio/PortfolioTotalChangeDialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.netWorthContainerBG};
`;

const AssetsContainer = styled.div`
  margin-bottom: 6px;
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: ${props => props.theme.netWorthContainerBR};
`;

const AssetsTitle = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  white-space: pre-line;
  cursor: pointer;
`;

const FieldValue = styled(CurrencyHeaderLabel)`
  cursor: ${props => (!props.onClick === false ? "pointer" : "auto")};
  width: fit-content;
`;

const AssetChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: fit-content;
  cursor: ${props => (props.isPlaceholder === true ? "auto" : "pointer")};
  filter: ${props => (props.isPlaceholder === true ? "opacity(0.3)" : null)};
`;

const AssetChangeTitle = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
`;

const AssetChangeValue = styled(ChangeLabel)`
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : null)};

  ${AssetChangeContainer}:hover & {
    text-decoration: underline;
  }
`;

const PlaceholderChangeValue = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
`;

class AssetsScorecard extends React.Component {
  constructor(props) {
    super(props);

    this.handleTotalChangeClick = this.handleTotalChangeClick.bind(this);
    this.handleAssetsTotalClick = this.handleAssetsTotalClick.bind(this);
  }

  handleAssetsTotalClick(e) {
    this.props.history.push({
      ...this.props.location,
      pathname: DashboardComponentExports.getPathnameForCategory(category.ASSET)
    });
  }

  handleTotalChangeClick(e, interval) {
    PortfolioTotalChangeDialog.show(this.props.history, this.props.location, interval, category.ASSET);
  }

  getWeekOrMonthlyOrYearlyChangelabel = () => {
    if (
      this.props.changeTotalsAndContributors.changes.year.isInsidePortfolioStartDate === true &&
      (!this.props.changeTotalsAndContributors.changes.year.total === false ||
        (this.props.portfolio.details.changeData &&
          this.props.portfolio.details.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.year.total === false &&
        this.props.portfolio.details.changeData.isAllChangeFetching;
      return (
        <AssetChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.YEAR)}>
          <AssetChangeTitle>{i18n.t("yearChange")}</AssetChangeTitle>
          <AssetChangeValue
            currency={this.props.portfolio.currency}
            startValue={this.props.changeTotalsAndContributors.changes.year.total}
            endValue={this.props.changeTotalsAndContributors.currentTotal}
            isUpdating={isUpdating}
            placeholder={
              !this.props.changeTotalsAndContributors.changes.year.total === true &&
              this.props.portfolio.details.changeData.isAllChangeFetching
            }
            isAnimationAllowed={false}
            alignPosition="left"
          />
        </AssetChangeContainer>
      );
    } else if (
      this.props.changeTotalsAndContributors.changes.month.isInsidePortfolioStartDate === true &&
      (!this.props.changeTotalsAndContributors.changes.month.total === false ||
        (this.props.portfolio.details.changeData &&
          this.props.portfolio.details.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.month.total === false &&
        this.props.portfolio.details.changeData.isAllChangeFetching;
      return (
        <AssetChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.MONTH)}>
          <AssetChangeTitle>{i18n.t("monthChange")}</AssetChangeTitle>
          <AssetChangeValue
            currency={this.props.portfolio.currency}
            startValue={this.props.changeTotalsAndContributors.changes.month.total}
            endValue={this.props.changeTotalsAndContributors.currentTotal}
            disableColor={this.props.disableTotalChangeColor}
            isUpdating={isUpdating}
            placeholder={
              !this.props.changeTotalsAndContributors.changes.month.total === true &&
              this.props.portfolio.details.changeData.isAllChangeFetching
            }
            isAnimationAllowed={false}
            alignPosition="left"
          />
        </AssetChangeContainer>
      );
    } else if (
      this.props.changeTotalsAndContributors.changes.week.isInsidePortfolioStartDate === true &&
      (!this.props.changeTotalsAndContributors.changes.week.total === false ||
        (this.props.portfolio.details.changeData &&
          this.props.portfolio.details.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.week.total === false &&
        this.props.portfolio.details.changeData.isAllChangeFetching;
      return (
        <AssetChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.WEEK)}>
          <AssetChangeTitle>{i18n.t("weekChange")}</AssetChangeTitle>
          <AssetChangeValue
            currency={this.props.portfolio.currency}
            startValue={this.props.changeTotalsAndContributors.changes.week.total}
            endValue={this.props.changeTotalsAndContributors.currentTotal}
            disableColor={this.props.disableTotalChangeColor}
            isUpdating={isUpdating}
            placeholder={
              !this.props.changeTotalsAndContributors.changes.week.total === true &&
              this.props.portfolio.details.changeData.isAllChangeFetching
            }
            isAnimationAllowed={false}
            alignPosition="left"
          />
        </AssetChangeContainer>
      );
    } else {
      return (
        <AssetChangeContainer isPlaceholder={true}>
          <AssetChangeTitle>{i18n.t("weekChange")}</AssetChangeTitle>
          <PlaceholderChangeValue>{`+${getSymbolForTickerUsingShortName(
            this.props.portfolio.currency
          )}XXXX (XX%)`}</PlaceholderChangeValue>
        </AssetChangeContainer>
      );
    }
  };

  render() {
    return (
      <Container className={this.props.className}>
        <AssetsContainer>
          <AssetsTitle onClick={this.handleAssetsTotalClick}>{i18n.t("assets")}</AssetsTitle>
          <FieldValue
            value={this.props.totalAssets}
            currency={this.props.portfolio.currency}
            currencyFontSize={18}
            valueFontSize={36}
            fontWeight={400}
            onClick={this.handleAssetsTotalClick}
            showZeroAsText={true}
            letterSpacing="-1.86px"
          />
          {this.props.changeTotalsAndContributors.changes.day.isInsidePortfolioStartDate === true && (
            <AssetChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.DAY)}>
              <AssetChangeTitle>{i18n.t("dayChange")}</AssetChangeTitle>
              <AssetChangeValue
                currency={this.props.portfolio.currency}
                startValue={this.props.changeTotalsAndContributors.changes.day.total}
                endValue={this.props.changeTotalsAndContributors.currentTotal}
                placeholder={
                  !this.props.changeTotalsAndContributors.changes.day.total === true &&
                  this.props.portfolio.details.changeData &&
                  this.props.portfolio.details.changeData.isDayChangeFetching
                }
                isUpdating={
                  !this.props.changeTotalsAndContributors.changes.day.total === false &&
                  this.props.portfolio.details.changeData &&
                  this.props.portfolio.details.changeData.isDayChangeFetching
                }
                isAnimationAllowed={false}
                alignPosition="left"
              />
            </AssetChangeContainer>
          )}
          {(this.props.changeTotalsAndContributors.changes.day.isInsidePortfolioStartDate === false ||
            !this.props.changeTotalsAndContributors.currentTotal === true) && (
            <AssetChangeContainer isPlaceholder={true}>
              <AssetChangeTitle>{i18n.t("dayChange")}</AssetChangeTitle>
              <PlaceholderChangeValue>{`+${getSymbolForTickerUsingShortName(
                this.props.portfolio.currency
              )}XXXX (XX%)`}</PlaceholderChangeValue>
            </AssetChangeContainer>
          )}
          {this.getWeekOrMonthlyOrYearlyChangelabel()}
        </AssetsContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolio: currentPortfolioSelector(state),
  totalAssets: portfolioTotalForCategory(state, undefined, category.ASSET),
  totalDebts: portfolioTotalForCategory(state, undefined, category.DEBT),
  changeTotalsAndContributors: getChangeTotalsWithContributorsForCurrentPortfolio(state, category.ASSET)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsScorecard));

import createWhitelistFilter from "redux-persist-transform-filter";
import { FETCH_BENEFICIARY_ERROR, UPDATE_BENEFICIARY } from "../actions/Common";

const initialState = {
  fetchBeneficiaryPending: false,
  fetchBeneficiaryError: null,
  beneficiaries: [],
  lastBeneficiaryUpdateTs: new Date().getTime()
};

export const beneficiaryPersistTransform = () => {
  return createWhitelistFilter("beneficiaries", ["beneficiaries", "lastBeneficiaryUpdateTs"]);
};

export function beneficiaryReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_BENEFICIARY: {
      const newState = { ...state };
      let beneficiaries = newState.beneficiaries;
      for (const item of action.beneficiaries) {
        const beneficiaryIndex = beneficiaries.findIndex(beneficiary => beneficiary.portfolioId === item.portfolioId);
        if (beneficiaryIndex === -1) {
          beneficiaries = [...beneficiaries, item];
        } else {
          beneficiaries[beneficiaryIndex] = item;
        }
      }

      newState.beneficiaries = beneficiaries;
      newState.fetchBeneficiaryPending = false;
      newState.fetchBeneficiaryError = null;
      newState.lastBeneficiaryUpdateTs = new Date().getTime();

      return newState;
    }
    case FETCH_BENEFICIARY_ERROR: {
      return state;
    }
    default:
      return state;
  }
}

export const beneficiarySelector = (state, portfolioId) => {
  const beneficiaries = state.beneficiaries.beneficiaries;
  if (beneficiaries === undefined || beneficiaries === null) {
    return null;
  }
  for (const beneficiary of beneficiaries) {
    if (beneficiary.portfolioId === portfolioId) {
      return beneficiary;
    }
  }
  return null;
};

export const beneficiariesSelector = state => {
  const beneficiaries = state.beneficiaries.beneficiaries;
  if (beneficiaries === undefined || beneficiaries === null) {
    return null;
  }
  return beneficiaries;
};

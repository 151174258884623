import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  blackFeatureTriggeredPromiseSelector,
  isNewUserInTrialSelector,
  isAppInWhiteLabelMode,
  isBlackOptinAllowedSelector
} from "@kubera/common";

import UpgradeToBlackPaywall from "./Upgrade";
import BlackHeadsUpDialog from "./HeadsUp";
import OptInPaywall from "./OptIn";
import WhiteLabelLinkPortfolios from "./WhiteLabelLinkPortfolios";

const BlackPaywalls = () => {
  const blackFeatureTriggeredPromise = useSelector(blackFeatureTriggeredPromiseSelector);
  const isNewUserInTrial = useSelector(isNewUserInTrialSelector);
  const isBlackOptinAllowed = useSelector(isBlackOptinAllowedSelector);

  const [showPaywall, setShowPaywall] = useState(false);

  const handlePositiveButtonClick = () => {
    blackFeatureTriggeredPromise.resolve(true);
    setShowPaywall(false);
  };

  const handleDismiss = () => {
    setShowPaywall(false);
    blackFeatureTriggeredPromise.resolve(false);
  };

  const handleMoreDetailsClick = () => {
    window.open("https://www.kubera.com/blog/kubera-black", "_blank");
  };

  useEffect(() => {
    if (blackFeatureTriggeredPromise === null) return;

    setShowPaywall(true);
  }, [blackFeatureTriggeredPromise]);

  if (!showPaywall) return null;

  if (isAppInWhiteLabelMode()) {
    return <WhiteLabelLinkPortfolios onPositiveButtonClick={handleDismiss} />;
  }

  if (isBlackOptinAllowed) {
    return (
      <OptInPaywall
        onDismiss={handleDismiss}
        onPositiveButtonClick={handlePositiveButtonClick}
        onMoreDetailsClick={handleMoreDetailsClick}
      />
    );
  }

  if (isNewUserInTrial) {
    return (
      <BlackHeadsUpDialog
        onPositiveButtonClick={handlePositiveButtonClick}
        onMoreDetailsClick={handleMoreDetailsClick}
      />
    );
  }

  return <UpgradeToBlackPaywall onDismiss={handleDismiss} onMoreDetailsClick={handleMoreDetailsClick} />;
};

export default BlackPaywalls;

import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  currentPortfolioSelector,
  portfolioTotalForCategory,
  getChangeTotalsWithContributorsForCurrentPortfolio,
  pastValueInterval,
  updateUserPreferences,
  getSymbolForTickerUsingShortName
} from "@kubera/common";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import DashboardComponentExports, { category } from "components/dashboard/DashboardComponentExports";
import ChangeLabel from "components/labels/ChangeLabel";
import PortfolioTotalChangeDialog from "components/portfolio/PortfolioTotalChangeDialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.netWorthContainerBG};
`;

const DebtsContainer = styled.div`
  margin-bottom: 6px;
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: ${props => props.theme.netWorthContainerBR};
`;

const DebtsTitle = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  white-space: pre-line;
  cursor: pointer;
`;

const FieldValue = styled(CurrencyHeaderLabel)`
  width: fit-content;
  cursor: ${props => (!props.onClick === false ? "pointer" : "auto")};
`;

const DebtChangeContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  cursor: ${props => (props.isPlaceholder === true ? "auto" : "pointer")};
  filter: ${props => (props.isPlaceholder === true ? "opacity(0.3)" : null)};
`;

const DebtChangeTitle = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
`;

const DebtChangeValue = styled(ChangeLabel)`
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : null)};
  cursor: pointer;

  ${DebtChangeContainer}:hover & {
    text-decoration: underline;
  }
`;

const PlaceholderChangeValue = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
`;

class DebtsScorecard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUnfundedDialog: false
    };

    this.handleTotalChangeClick = this.handleTotalChangeClick.bind(this);
    this.handleDebtsTotalClick = this.handleDebtsTotalClick.bind(this);
  }

  handleDebtsTotalClick(e) {
    this.props.history.push({
      ...this.props.location,
      pathname: DashboardComponentExports.getPathnameForCategory(category.DEBT)
    });
  }

  handleTotalChangeClick(e, interval) {
    PortfolioTotalChangeDialog.show(this.props.history, this.props.location, interval, category.DEBT);
  }

  getWeekOrMonthlyOrYearlyChangelabel = () => {
    if (
      this.props.changeTotalsAndContributors.changes.year.isInsidePortfolioStartDate === true &&
      !this.props.changeTotalsAndContributors.currentTotal === false &&
      (!this.props.changeTotalsAndContributors.changes.year.total === false ||
        (this.props.portfolio.details.changeData &&
          this.props.portfolio.details.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.year.total === false &&
        this.props.portfolio.details.changeData.isAllChangeFetching;
      return (
        <DebtChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.YEAR)}>
          <DebtChangeTitle>{i18n.t("yearChange")}</DebtChangeTitle>
          <DebtChangeValue
            currency={this.props.portfolio.currency}
            startValue={this.props.changeTotalsAndContributors.changes.year.total}
            endValue={this.props.changeTotalsAndContributors.currentTotal}
            isUpdating={isUpdating}
            placeholder={
              !this.props.changeTotalsAndContributors.changes.year.total === true &&
              this.props.portfolio.details.changeData.isAllChangeFetching
            }
            isAnimationAllowed={false}
            alignPosition="left"
            disableColor={true}
          />
        </DebtChangeContainer>
      );
    } else if (
      this.props.changeTotalsAndContributors.changes.month.isInsidePortfolioStartDate === true &&
      !this.props.changeTotalsAndContributors.currentTotal === false &&
      (!this.props.changeTotalsAndContributors.changes.month.total === false ||
        (this.props.portfolio.details.changeData &&
          this.props.portfolio.details.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.month.total === false &&
        this.props.portfolio.details.changeData.isAllChangeFetching;
      return (
        <DebtChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.MONTH)}>
          <DebtChangeTitle>{i18n.t("monthChange")}</DebtChangeTitle>
          <DebtChangeValue
            currency={this.props.portfolio.currency}
            startValue={this.props.changeTotalsAndContributors.changes.month.total}
            endValue={this.props.changeTotalsAndContributors.currentTotal}
            isUpdating={isUpdating}
            placeholder={
              !this.props.changeTotalsAndContributors.changes.month.total === true &&
              this.props.portfolio.details.changeData.isAllChangeFetching
            }
            isAnimationAllowed={false}
            alignPosition="left"
            disableColor={true}
          />
        </DebtChangeContainer>
      );
    } else if (
      this.props.changeTotalsAndContributors.changes.week.isInsidePortfolioStartDate === true &&
      !this.props.changeTotalsAndContributors.currentTotal === false &&
      (!this.props.changeTotalsAndContributors.changes.week.total === false ||
        (this.props.portfolio.details.changeData &&
          this.props.portfolio.details.changeData.isAllChangeFetching === true))
    ) {
      const isUpdating =
        !this.props.changeTotalsAndContributors.changes.week.total === false &&
        this.props.portfolio.details.changeData.isAllChangeFetching;
      return (
        <DebtChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.WEEK)}>
          <DebtChangeTitle>{i18n.t("weekChange")}</DebtChangeTitle>
          <DebtChangeValue
            currency={this.props.portfolio.currency}
            startValue={this.props.changeTotalsAndContributors.changes.week.total}
            endValue={this.props.changeTotalsAndContributors.currentTotal}
            isUpdating={isUpdating}
            placeholder={
              !this.props.changeTotalsAndContributors.changes.week.total === true &&
              this.props.portfolio.details.changeData.isAllChangeFetching
            }
            isAnimationAllowed={false}
            alignPosition="left"
            disableColor={true}
          />
        </DebtChangeContainer>
      );
    } else {
      return (
        <DebtChangeContainer isPlaceholder={true}>
          <DebtChangeTitle>{i18n.t("weekChange")}</DebtChangeTitle>
          <PlaceholderChangeValue>{`+${getSymbolForTickerUsingShortName(
            this.props.portfolio.currency
          )}XXXX (XX%)`}</PlaceholderChangeValue>
        </DebtChangeContainer>
      );
    }
  };

  render() {
    return (
      <Container className={this.props.className}>
        <DebtsContainer>
          <DebtsTitle onClick={this.handleDebtsTotalClick}>{i18n.t("debts")}</DebtsTitle>
          <FieldValue
            value={this.props.totalDebts}
            currency={this.props.portfolio.currency}
            currencyFontSize={18}
            valueFontSize={36}
            fontWeight={400}
            onClick={this.handleDebtsTotalClick}
            showZeroAsText={true}
            letterSpacing="-1.86px"
          />
          {this.props.changeTotalsAndContributors.changes.day.isInsidePortfolioStartDate === true &&
            !this.props.changeTotalsAndContributors.currentTotal === false && (
              <DebtChangeContainer onClick={e => this.handleTotalChangeClick(e, pastValueInterval.DAY)}>
                <DebtChangeTitle>{i18n.t("dayChange")}</DebtChangeTitle>
                <DebtChangeValue
                  currency={this.props.portfolio.currency}
                  startValue={this.props.changeTotalsAndContributors.changes.day.total}
                  endValue={this.props.changeTotalsAndContributors.currentTotal}
                  placeholder={
                    !this.props.changeTotalsAndContributors.changes.day.total === true &&
                    this.props.portfolio.details.changeData &&
                    this.props.portfolio.details.changeData.isDayChangeFetching
                  }
                  isUpdating={
                    !this.props.changeTotalsAndContributors.changes.day.total === false &&
                    this.props.portfolio.details.changeData &&
                    this.props.portfolio.details.changeData.isDayChangeFetching
                  }
                  isAnimationAllowed={false}
                  alignPosition="left"
                  disableColor={true}
                />
              </DebtChangeContainer>
            )}
          {(this.props.changeTotalsAndContributors.changes.day.isInsidePortfolioStartDate === false ||
            !this.props.changeTotalsAndContributors.currentTotal === true) && (
            <DebtChangeContainer isPlaceholder={true}>
              <DebtChangeTitle>{i18n.t("dayChange")}</DebtChangeTitle>
              <PlaceholderChangeValue>{`+${getSymbolForTickerUsingShortName(
                this.props.portfolio.currency
              )}XXXX (XX%)`}</PlaceholderChangeValue>
            </DebtChangeContainer>
          )}
          {this.getWeekOrMonthlyOrYearlyChangelabel()}
        </DebtsContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolio: currentPortfolioSelector(state),
  totalDebts: portfolioTotalForCategory(state, undefined, category.DEBT),
  changeTotalsAndContributors: getChangeTotalsWithContributorsForCurrentPortfolio(state, category.DEBT)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DebtsScorecard));

import { createSelector } from "reselect";
import { store } from "../../store";
import eventBus from "../../eventBus";
import { ApiClient } from "../../api/ApiClient";
import { enqueueItem, SyncItem, SyncItemType } from "./SyncActions";
import {
  converUIDateFormatToCustodianHistoryApiDateFormat,
  getCustodianHistoryApiFormattedDateString,
  getDateInKuberaFormat,
  getUuid,
  guessDateInCustodianHistoryUIFormat,
  getCustodianHistoryFormattedDateString
} from "../../utilities/Number";
import {
  getTickersForIds,
  addTickerInfoAction,
  getTickersForText,
  convertPVSTRateToValueExchangeRate
} from "./TickerActions";
import {
  copyLinkingInfoFromParentCustodian,
  getNetWorthChartStartDateForPortfolio
} from "../reducers/PortfolioReducer";
import { custodianDetailsSelector, fundScheduleSelector } from "../reducers/CustodianDetailsReducer";
import {
  updateCustodianInBulkAction,
  insertCustodianAction,
  updateCustodian,
  setSectionUpdated,
  updateSectionIrr,
  getIrrValue,
  updateCustodianLocal
} from "./PortfolioActions";
import {
  irrTypes,
  getChartDataFromResponse,
  getExchangeRateDetails,
  updateDashboardAction,
  toastType,
  RESET_CUSTODIAN_DETAILS,
  FETCH_CUSTODIAN_DETAILS,
  FETCH_CUSTODIAN_DETAILS_SUCCESS,
  FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS,
  SET_CURRENT_STATE,
  SET_INNER_DETAILS_STATE,
  FETCH_CUSTODIAN_DETAILS_ERROR,
  UPDATE_CUSTODIAN_HISTORY,
  REFRESH_CUSTODIAN_HISTORY,
  UPDATE_CUSTODIAN_CASHFLOW,
  DELETE_CUSTODIAN_CASHFLOW,
  ADD_PENDING_IRR_REQUEST,
  REMOVE_PENDING_IRR_REQUEST,
  filterChartDataBasedOnPortfolioStartDate,
  getYTDCustodianChartData,
  updateCustodianDetailsAction,
  DELETE_CUSTODIAN_HISTORY,
  fundScheduleTypes,
  UPDATE_FUND_SCHEDULE,
  DELETE_FUND_SCHEDULE,
  GET_CUSTODIAN_CHART,
  GET_CUSTODIAN_CHART_SUCCESS,
  GET_CUSTODIAN_CHART_ERROR
} from "./Common";
import {
  currentPortfolioSelector,
  custodianSelector,
  portfoliosSelector,
  chartTimeRange,
  getExchangeRate,
  getTickerUsingId,
  getTickerUsingShortName,
  UNKNOWN_TICKER_SHORT_NAME
} from "../reducers/Common";
import { apiErrorCodes } from "../../api/ApiResponse";
import { Toast, showToastAction } from "./ToastActions";
import i18n from "i18next";
import { getState, getHashParams } from "../../utilities";
import DeferredPromise from "utilities/DeferredPromise";

export const fetchCustodianDetailsAction = () => ({
  type: FETCH_CUSTODIAN_DETAILS
});

export const fetchCustodianDetailsSuccessAction = details => ({
  type: FETCH_CUSTODIAN_DETAILS_SUCCESS,
  details
});

export const fetchCustodianDetailsHistorySuccessAction = (history, isCostHistory, clearPreviousHistory) => ({
  type: FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS,
  history,
  isCostHistory,
  clearPreviousHistory
});

export const getCustodianChartAction = () => ({
  type: GET_CUSTODIAN_CHART
});

export const getCustodianChartSuccessAction = chartData => ({
  type: GET_CUSTODIAN_CHART_SUCCESS,
  chartData
});

export const getCustodianChartErrorAction = error => ({
  type: GET_CUSTODIAN_CHART_ERROR,
  error
});

export const setCurrentStateAction = state => ({
  type: SET_CURRENT_STATE,
  state
});

export const setInnerDetailsStateAction = scrollTop => ({
  type: SET_INNER_DETAILS_STATE,
  scrollTop
});

export const fetchCustodianDetailsErrorAction = error => ({
  type: FETCH_CUSTODIAN_DETAILS_ERROR,
  error
});

export const resetCustodianDetailsAction = () => ({
  type: RESET_CUSTODIAN_DETAILS
});

export const deleteCustodianHistoryAction = (historyId, isCostHistory) => ({
  type: DELETE_CUSTODIAN_HISTORY,
  historyId,
  isCostHistory
});

export const updateCustodianHistoryAction = (history, isCostHistory) => ({
  type: UPDATE_CUSTODIAN_HISTORY,
  history,
  isCostHistory
});

export const refreshCustodianHistoryAction = isRefreshing => ({
  type: REFRESH_CUSTODIAN_HISTORY,
  isRefreshing
});

export const updateCustodianCashflowAction = cashflow => ({
  type: UPDATE_CUSTODIAN_CASHFLOW,
  cashflow
});

export const deleteCustodianCashflowAction = cashflowId => ({
  type: DELETE_CUSTODIAN_CASHFLOW,
  cashflowId
});

export const addPendingIrrRequestAction = requestId => ({
  type: ADD_PENDING_IRR_REQUEST,
  requestId
});

export const removePendingIrrRequestAction = requestId => ({
  type: REMOVE_PENDING_IRR_REQUEST,
  requestId
});

export const updateFundScheduleAction = fundSchedule => ({
  type: UPDATE_FUND_SCHEDULE,
  fundSchedule
});

export const deleteFundScheduleAction = id => ({
  type: DELETE_FUND_SCHEDULE,
  id
});

export const showCopiedToast = () => {
  return dispatch => {
    const toast = new Toast(toastType.TIP, i18n.t("numberCopied"), 1500, () => {}, () => {});
    dispatch(showToastAction(toast));
  };
};

export const markHoldingsToast = markedAsInvestable => {
  return dispatch => {
    const toastMessage = markedAsInvestable
      ? i18n.t("markHoldings.toast.investable")
      : i18n.t("markHoldings.toast.nonInvestable");
    const toast = new Toast(toastType.TIP, toastMessage, 1500, () => {}, () => {});
    dispatch(showToastAction(toast));
  };
};

export const copyCustodianHistoryAfterDate = (sectionId, custodianId, properties, onSuccess) => {
  return dispatch => {
    ApiClient.copyCustodianHistoryAfterDate(getUuid(), sectionId, custodianId, properties)
      .then(apiData => {
        const history = apiData.payload.history;
        dispatch(fetchCustodianDetailsHistorySuccessAction(history, false, true));

        setTimeout(() => {
          onSuccess(apiData.payload.info);
          dispatch(refreshCustodianHistoryAction(false));
        }, 0);
      })
      .catch(apiError => {
        dispatch(refreshCustodianHistoryAction(false));
      });
  };
};

export const fetchCustodianDetails = (custodianId, updateCustodian = false, matchIdWithUrlHashParam = true) => {
  return dispatch => {
    dispatch(fetchCustodianDetailsAction());

    const maxRetryCount = 3;
    var retryCount = 0;
    const fetchDetails = () => {
      ApiClient.getCustodianDetails(getUuid(), custodianId)
        .then(detailsApiData => {
          const detailsData = detailsApiData.payload;
          detailsData.isHistoryLoaded = detailsData.history.length === 0;

          if (updateCustodian) {
            dispatch(updateCustodianLocal(detailsData.info));
          }

          if (!detailsData.info.parentId === false) {
            const portfolios = portfoliosSelector(store.getState());
            copyLinkingInfoFromParentCustodian(portfolios, detailsData.info.parentId, detailsData.info);
          }

          if (detailsData.info.irrType === irrTypes.COSTBASIS && detailsData.info.cost === null) {
            detailsData.info.irrType = irrTypes.CASHFLOW;
          }

          detailsData.costHistory = detailsData.costBasis || [];
          detailsData.isCostHistoryLoaded = detailsData.costHistory.length === 0;

          const getUnknownTickerIds = () => {
            var unknownTickerIds = new Set();
            var tickerIdsToTest = new Set();

            if (!detailsData.info.valueTickerId === false) {
              tickerIdsToTest.add(detailsData.info.valueTickerId);
            }
            if (!detailsData.info.costTickerId === false) {
              tickerIdsToTest.add(detailsData.info.costTickerId);
            }

            for (const holding of detailsData.holdings) {
              if (!holding.valueTickerId === false) {
                tickerIdsToTest.add(holding.valueTickerId);
              }
              if (!holding.costTickerId === false) {
                tickerIdsToTest.add(holding.costTickerId);
              }
              const currentPortfolio = currentPortfolioSelector(store.getState());
              dispatch(insertCustodianAction(currentPortfolio.id, holding));
            }

            for (const history of detailsData.history) {
              if (!history.valueTickerId === false) {
                tickerIdsToTest.add(history.valueTickerId);
              }
            }

            for (const cashflow of detailsData.cashFlow) {
              if (!cashflow.cashInTickerId === false) {
                tickerIdsToTest.add(cashflow.cashInTickerId);
              }
              if (!cashflow.cashOutTickerId === false) {
                tickerIdsToTest.add(cashflow.cashOutTickerId);
              }
            }

            for (const tickerId of Array.from(tickerIdsToTest)) {
              if (getTickerUsingId(tickerId).shortName === UNKNOWN_TICKER_SHORT_NAME) {
                unknownTickerIds.add(tickerId);
              }
            }
            return Array.from(unknownTickerIds);
          };

          const unknownTickerIds = getUnknownTickerIds();
          if (unknownTickerIds.length > 0) {
            dispatch(
              getTickersForIds(
                unknownTickerIds,
                () => {
                  const urlHashParams = getHashParams(window.location);
                  const id = urlHashParams["id"];
                  if (id === custodianId) {
                    dispatch(fetchCustodianDetailsSuccessAction(detailsData));
                  }
                },
                apiError => {
                  dispatch(fetchCustodianDetailsErrorAction(apiError));
                }
              )
            );
          } else if (matchIdWithUrlHashParam === true) {
            const urlHashParams = getHashParams(window.location);
            const id = urlHashParams["id"];
            if (id === custodianId) {
              dispatch(fetchCustodianDetailsSuccessAction(detailsData));
            }
          } else {
            dispatch(fetchCustodianDetailsSuccessAction(detailsData));
          }
        })
        .catch(apiError => {
          if (apiError.errorCode === apiErrorCodes.INVALID_INPUT && retryCount < maxRetryCount) {
            retryCount++;

            setTimeout(() => {
              fetchDetails();
            }, 3000);
          } else {
            dispatch(fetchCustodianDetailsErrorAction(apiError));
          }
        });
    };

    fetchDetails();
  };
};

export const fetchCustodianDetailsHistory = (
  custodianId,
  timeStamp,
  isCostHistory = false,
  clearPreviousHistory = false,
  onCompletion = null
) => {
  return dispatch => {
    dispatch(fetchCustodianDetailsAction());

    ApiClient.getCustodianDetailsHistory(getUuid(), custodianId, timeStamp, isCostHistory)
      .then(apiData => {
        const history = isCostHistory ? apiData.payload.costBasis : apiData.payload.history;
        dispatch(fetchCustodianDetailsHistorySuccessAction(history, isCostHistory, clearPreviousHistory));

        if (onCompletion) {
          onCompletion();
        }
      })
      .catch(apiError => {
        dispatch(fetchCustodianDetailsErrorAction(apiError));

        if (onCompletion) {
          onCompletion();
        }
      });
  };
};

export const deleteCustodianHistory = (sectionId, custodianId, historyId, isCostHistory = false, onCompletion) => {
  return dispatch => {
    dispatch(deleteCustodianHistoryAction(historyId, isCostHistory));

    const request = idempotentId => ApiClient.deleteCustodianHistory(idempotentId, sectionId, custodianId, historyId);
    const syncItem = new SyncItem(SyncItemType.DELETE, history.id, request, 0, false, apiData => {
      if (onCompletion) {
        onCompletion();
      }
    });
    dispatch(enqueueItem(syncItem));
  };
};

export const updateCustodianHistory = (
  isFirstEdit,
  sectionId,
  custodianId,
  history,
  isCostHistory = false,
  onCompletion = null
) => {
  return (dispatch, getState) => {
    if (history) {
      history.date = converUIDateFormatToCustodianHistoryApiDateFormat(history.date);

      if (!history.date) {
        return;
      }

      const apiKeys = [
        "id",
        "date",
        "cost",
        "value",
        "costTickerId",
        "costExchangeRate",
        "valueTickerId",
        "valueExchangeRate"
      ];

      const apiObject = Object.keys(history)
        .filter(key => apiKeys.includes(key) && history[key] !== null)
        .reduce((obj, key) => {
          obj[key] = history[key];
          return obj;
        }, {});

      // While updating a custodian if value field is non-null ensure that the valueTickerId
      // and valueExchangeRate fields are also set correctly
      if (history.value != null) {
        if (history.valueTickerId === 171 && history.rate) {
          apiObject.valueExchangeRate = convertPVSTRateToValueExchangeRate.bind(getState())(
            history.rate,
            guessDateInCustodianHistoryUIFormat(history.date).dateString,
            false
          );
        }
      }

      dispatch(updateCustodianHistoryAction(apiObject, isCostHistory));

      if (isFirstEdit === true) {
        const request = idempotentId =>
          ApiClient.createCustodianHistory(idempotentId, sectionId, custodianId, apiObject);
        const syncItem = new SyncItem(SyncItemType.CREATE, history.id, request, 0, false, apiData => {
          if (onCompletion) {
            onCompletion();
          }
        });
        dispatch(enqueueItem(syncItem));
      } else {
        const request = idempotentId =>
          ApiClient.updateCustodianHistory(idempotentId, sectionId, custodianId, apiObject);
        const syncItem = new SyncItem(SyncItemType.UPDATE, history.id, request, 1000, true, apiData => {
          if (onCompletion) {
            onCompletion();
          }
        });
        dispatch(enqueueItem(syncItem));
      }
    }
  };
};

export const deleteOwnershipHistoryForCustodian = (sectionId, custodianId, ownershipId) => {
  return dispatch => {
    if (!sectionId || !custodianId || !ownershipId) {
      return;
    }

    const custodian = custodianSelector(store.getState(), custodianId);
    if (!custodian.parentId === false) {
      const parentCustodian = custodianSelector(store.getState(), custodian.parentId);
      custodianId = parentCustodian.id;
      sectionId = parentCustodian.sectionId;
    }

    const request = idempotentId =>
      ApiClient.deleteCustodianOwnership(idempotentId, sectionId, custodianId, ownershipId);
    const syncItem = new SyncItem(SyncItemType.DELETE, ownershipId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const updateOwnershipHistoryForCustodian = (
  isNewEntry,
  sectionId,
  custodianId,
  ownershipEntry,
  onCompletion = null
) => {
  return (dispatch, getState) => {
    if (
      !custodianId ||
      !ownershipEntry ||
      !ownershipEntry.date ||
      (!ownershipEntry.percentage && ownershipEntry.percentage !== 0) ||
      !ownershipEntry.id
    ) {
      return;
    }

    const currentPortfolio = currentPortfolioSelector(getState());
    const custodian = custodianSelector(getState(), custodianId);
    if (!custodian.parentId === false) {
      const parentCustodian = custodianSelector(getState(), custodian.parentId);
      custodianId = parentCustodian.id;
      sectionId = parentCustodian.sectionId;
    }

    const handlePayload = payload => {
      const updatedCustodian = payload.info;
      const custodian = custodianSelector(getState(), updatedCustodian.id);
      if (getIrrValue(custodian?.irr, true) !== getIrrValue(updatedCustodian.irr, true)) {
        dispatch(updateSectionIrr([updatedCustodian.sectionId]));
      }

      dispatch(updateCustodianInBulkAction(currentPortfolio.id, [updatedCustodian]));

      payload.holdings?.forEach(holding => {
        dispatch(updateCustodianHolding(holding, holding));
      });
    };

    if (isNewEntry === true) {
      const request = idempotentId =>
        ApiClient.createCustodianOwnership(idempotentId, sectionId, custodianId, ownershipEntry);
      const syncItem = new SyncItem(SyncItemType.CREATE, ownershipEntry.id, request, 1000, true, apiData => {
        if (onCompletion) {
          onCompletion();
        }
        handlePayload(apiData.payload);
      });
      dispatch(enqueueItem(syncItem));
    } else {
      const request = idempotentId =>
        ApiClient.updateCustodianOwnership(idempotentId, sectionId, custodianId, ownershipEntry.id, ownershipEntry);
      const syncItem = new SyncItem(SyncItemType.UPDATE, ownershipEntry.id, request, 1000, true, apiData => {
        if (onCompletion) {
          onCompletion();
        }
        handlePayload(apiData.payload);
      });
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const updateCostBasisHistoryForCustodian = (sectionId, custodianId, costBasisId, history) => {
  return dispatch => {
    if (history) {
      history.date = converUIDateFormatToCustodianHistoryApiDateFormat(history.date);

      if (!history.date) {
        return;
      }
      dispatch(updateCustodianHistoryAction(history, true));
      const request = idempotentId =>
        ApiClient.updateCostBasisHistoryForCustodian(idempotentId, sectionId, custodianId, costBasisId, history);
      const syncItem = new SyncItem(SyncItemType.UPDATE, history.id, request, 1000, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    }
  };
};

export const deleteCostBasisHistoryForCustodian = (sectionId, custodianId, costBasisId) => {
  return dispatch => {
    dispatch(deleteCustodianHistoryAction(costBasisId, true));
    const request = idempotentId =>
      ApiClient.deleteCostBasisHistoryForCustodian(idempotentId, sectionId, custodianId, costBasisId, history);
    const syncItem = new SyncItem(SyncItemType.UPDATE, history.id, request, 1000, true, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const getCustodianChart = (custodianId, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    dispatch(getCustodianChartAction());
    const netWorthChartStartDate = getNetWorthChartStartDateForPortfolio(getState());
    ApiClient.getCustodianChart(getUuid(), custodianId)
      .then(apiData => {
        let chartData = apiData.payload;
        chartData.chart = getChartDataFromResponse(chartData.chart, "data");

        if (!chartData === false) {
          for (const chartDuration in chartData.chart) {
            if (chartDuration === chartTimeRange.LAST_YEAR_END) {
              continue;
            }

            chartData.chart[chartDuration].data =
              chartData.chart[chartDuration].data &&
              chartData.chart[chartDuration].data
                .map(dataPoint => {
                  dataPoint.value = Math.kuberaFloor(dataPoint.value);
                  return dataPoint;
                })
                .filter(
                  dataPoint => getDateInKuberaFormat(dataPoint.date).getTime() >= netWorthChartStartDate.getTime()
                );
          }
        }
        chartData = filterChartDataBasedOnPortfolioStartDate(chartData);
        const ytdCustodianChartData = getYTDCustodianChartData(
          chartData,
          chartData.chart[chartTimeRange.LAST_YEAR_END],
          netWorthChartStartDate
        );
        chartData.chart[chartTimeRange.YTD] = ytdCustodianChartData;
        dispatch(getCustodianChartSuccessAction(chartData));
        onSuccess(chartData);
      })
      .catch(apiError => {
        dispatch(getCustodianChartErrorAction(apiError));
        onSuccess(apiError);
      });
  };
};

export const fetchTickerDetails = (inputText, forDate, onSuccess, onError, parseInputText = true) => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    const portfolioTicker = getTickerUsingShortName(currentPortfolio.currency);

    dispatch(
      getTickersForText(
        inputText,
        tickers => {
          for (const tickerInfo of tickers) {
            dispatch(addTickerInfoAction(tickerInfo.info, tickerInfo.rate, forDate));
          }

          if (tickers.length > 0) {
            // this function is used within the details component. cost basis/cash flow is more likely to be entered in fiat/crypto so backend sort order is overrided
            const sortingCriteria = {
              fiat: 1,
              crypto: 2
            };

            const sortedTickers = tickers.sort((a, b) => {
              const typeA = a.info.type;
              const typeB = b.info.type;

              const priorityA = sortingCriteria[typeA] || Infinity;
              const priorityB = sortingCriteria[typeB] || Infinity;

              return priorityA === priorityB ? 0 : priorityA - priorityB;
            });
            const tickerInfo = sortedTickers[0].info;
            const forCurrentDate =
              getCustodianHistoryApiFormattedDateString(new Date().getTime()) ===
              getCustodianHistoryApiFormattedDateString(forDate.getTime());
            const rate = forCurrentDate
              ? getExchangeRate(tickerInfo.shortName, currentPortfolio.currency, false)
              : getExchangeRate(tickerInfo.shortName, currentPortfolio.currency, false, forDate);
            const exchangeRate = forCurrentDate
              ? getExchangeRateDetails(portfolioTicker.id, rate)
              : getExchangeRateDetails(portfolioTicker.id, rate, forDate);

            const result = {
              exchangeRateDetails: exchangeRate,
              tickerId: tickerInfo.id,
              tickerShortName: tickerInfo.shortName,
              tickers: sortedTickers,
              exchangeRate: rate
            };

            onSuccess(result);
          } else {
            onSuccess(null);
          }
        },
        apiError => {
          if (apiError.errorCode === apiErrorCodes.INVALID_INPUT) {
            return onSuccess(null);
          }
          return onError(apiError);
        },
        parseInputText,
        forDate,
        currentPortfolio.currency
      )
    );
  };
};

export const updateCustodianCashflow = (
  isFirstEdit,
  custodianId,
  propertiesToUpdate,
  onSuccess = () => {},
  onError = error => {}
) => {
  return (dispatch, getState) => {
    if (propertiesToUpdate) {
      const custodian = custodianSelector(getState(), custodianId);
      dispatch(setSectionUpdated(custodian.sectionId));

      propertiesToUpdate.date = converUIDateFormatToCustodianHistoryApiDateFormat(propertiesToUpdate.date);

      if (!propertiesToUpdate.date) {
        return;
      }

      if (propertiesToUpdate.cashInExchangeRate && propertiesToUpdate.date) {
        try {
          const rateDetails = JSON.parse(propertiesToUpdate.cashInExchangeRate);
          if (rateDetails.date !== propertiesToUpdate.date) {
            rateDetails.date = propertiesToUpdate.date;
            propertiesToUpdate.cashInExchangeRate = JSON.stringify(rateDetails);
          }
        } catch (e) {}
      }

      if (propertiesToUpdate.cashOutExchangeRate && propertiesToUpdate.date) {
        try {
          const rateDetails = JSON.parse(propertiesToUpdate.cashOutExchangeRate);
          if (rateDetails.date !== propertiesToUpdate.date) {
            rateDetails.date = propertiesToUpdate.date;
            propertiesToUpdate.cashOutExchangeRate = JSON.stringify(rateDetails);
          }
        } catch (e) {}
      }

      const apiKeys = [
        "id",
        "date",
        "note",
        "cashIn",
        "cashInTickerId",
        "cashInExchangeRate",
        "cashOut",
        "cashOutTickerId",
        "cashOutExchangeRate"
      ];
      const apiObject = Object.keys(propertiesToUpdate)
        .filter(key => apiKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = propertiesToUpdate[key];
          return obj;
        }, {});

      dispatch(updateCustodianCashflowAction(apiObject));
      dispatch(addPendingIrrRequestAction(apiObject.id));

      if (isFirstEdit === true) {
        const request = idempotentId => ApiClient.createCustodianCashflow(idempotentId, custodianId, apiObject);
        const syncItem = new SyncItem(
          SyncItemType.CREATE,
          propertiesToUpdate.id,
          request,
          0,
          false,
          apiData => {
            const { irrType } = propertiesToUpdate;
            dispatch(
              updateCustodianDueToCashflowUpdate(
                irrType ? { ...apiData.payload, irrType } : apiData.payload,
                apiObject.id
              )
            );
            onSuccess();
          },
          error => {
            onError(error);
          }
        );
        dispatch(enqueueItem(syncItem));
      } else {
        const request = idempotentId =>
          ApiClient.updateCustodianCashflow(idempotentId, custodianId, apiObject.id, apiObject);
        const syncItem = new SyncItem(
          SyncItemType.UPDATE,
          propertiesToUpdate.id,
          request,
          1000,
          true,
          apiData => {
            dispatch(updateCustodianDueToCashflowUpdate(apiData.payload, apiObject.id));
            onSuccess();
          },
          error => {
            onError(error);
          }
        );
        dispatch(enqueueItem(syncItem));
      }
    }
  };
};

export const deleteCustodianCashflow = (custodianId, cashflowId) => {
  return dispatch => {
    dispatch(deleteCustodianCashflowAction(cashflowId));
    dispatch(addPendingIrrRequestAction(cashflowId));

    const request = idempotentId => ApiClient.deleteCustodianCashflow(idempotentId, custodianId, cashflowId);
    const syncItem = new SyncItem(SyncItemType.DELETE, cashflowId, request, 0, false, apiData => {
      dispatch(updateCustodianDueToCashflowUpdate(apiData.payload, cashflowId));
    });
    dispatch(enqueueItem(syncItem));
  };
};

export const deleteAllCustodianCashflows = custodianId => {
  return (dispatch, getState) => {
    const custodianDetails = custodianDetailsSelector(getState());
    custodianDetails.cashFlow = [];
    custodianDetails.info.irr = null;
    dispatch(updateCustodianDetailsAction(custodianDetails));

    const request = idempotentId => ApiClient.deleteAllCustodianCashflows(idempotentId, custodianId);
    const syncItem = new SyncItem(SyncItemType.DELETE, custodianId, request, 0, false, apiData => {
      dispatch(updateCustodianDueToCashflowUpdate(apiData.payload));
    });
    dispatch(enqueueItem(syncItem));
  };
};

export const updateCustodianDueToCashflowUpdate = (updatedCustodian, cashflowId = undefined) => {
  return dispatch => {
    const custodianDetails = custodianDetailsSelector(store.getState());
    if (custodianDetails && updatedCustodian.id === custodianDetails.info.id) {
      custodianDetails.info = updatedCustodian;
      dispatch(updateCustodianDetailsAction(custodianDetails));
      dispatch(removePendingIrrRequestAction(cashflowId));
    }

    const currentPortfolio = currentPortfolioSelector(store.getState());
    const currentCustodian = custodianSelector(store.getState(), updatedCustodian.id, currentPortfolio.id);
    dispatch(updateCustodianInBulkAction(currentPortfolio.id, [updatedCustodian]));
    if (getIrrValue(currentCustodian.irr, true) !== getIrrValue(updatedCustodian.irr, true)) {
      dispatch(updateSectionIrr([updatedCustodian.sectionId]));
    }
    dispatch(updateDashboardAction([updatedCustodian.id]));
  };
};

export function getOwnershipValueForCustodian(custodianId) {
  const state = getState(this);
  const custodian = custodianSelector(state, custodianId);
  if (!custodian.parentId === false) {
    const parentCustodian = custodianSelector(state, custodian.parentId);
    return parentCustodian ? parentCustodian.ownership : null;
  }

  return !custodian.ownership === false || custodian.ownership === 0 ? custodian.ownership : 100;
}

export const getOwnershipValueForCustodianReselectFn = createSelector(
  [state => state],
  state => getOwnershipValueForCustodian.bind(state)
);

export const updateCustodianIrrType = (custodianId, irrType, forceUpdate = false, updateCustodian = true) => {
  return dispatch => {
    return new Promise(resolve => {
      const custodianDetails = custodianDetailsSelector(store.getState());
      if (custodianDetails) {
        custodianDetails.info.irr = null;
        custodianDetails.info.irrType = irrType;
        dispatch(updateCustodianDetailsAction(custodianDetails));
      }

      const currentPortfolio = currentPortfolioSelector(store.getState());
      const updatedCustodian = custodianSelector(store.getState(), custodianId);
      const previousIrr = updatedCustodian.irr;
      updatedCustodian.irr = null;
      updatedCustodian.irrType = irrType;
      updatedCustodian.previousIrr = previousIrr;
      dispatch(updateCustodianInBulkAction(currentPortfolio.id, [updatedCustodian]));
      dispatch(updateDashboardAction([custodianId]));

      if (forceUpdate === false && irrType === irrTypes.COSTBASIS && !updatedCustodian.cost === true) {
        return;
      }
      if (forceUpdate === false && irrType === irrTypes.CASHFLOW && custodianDetails.cashFlow.length === 0) {
        return;
      }

      const request = idempotentId => ApiClient.updateCustodianIrrType(idempotentId, custodianId, irrType);
      const syncItem = new SyncItem(SyncItemType.UPDATE, "irr" + custodianId, request, 0, false, apiData => {
        if (updateCustodian) {
          dispatch(updateCustodianDueToCashflowUpdate(apiData.payload));
        }
        resolve(apiData.payload);
      });
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const updateCustodianHolding = (holding, propertiesToUpdate) => {
  return dispatch => {
    return new Promise(resolve => {
      const currentPortfolio = currentPortfolioSelector(store.getState());
      const updatedHolding = { ...holding, ...propertiesToUpdate };
      dispatch(insertCustodianAction(currentPortfolio.id, updatedHolding));

      const custodianDetails = custodianDetailsSelector(store.getState());
      const holdingIndex = custodianDetails.holdings.findIndex(item => item.id === holding.id);
      if (holdingIndex !== -1) {
        custodianDetails.holdings[holdingIndex] = updatedHolding;
      }
      dispatch(updateCustodianDetailsAction(custodianDetails));

      dispatch(
        updateCustodian(false, holding.id, propertiesToUpdate, false, () => {
          resolve(updatedHolding);
        })
      );
    });
  };
};

export const updateFundSchedule = (isFirstEdit, custodianId, fundSchedule) => {
  return dispatch => {
    if (fundSchedule) {
      fundSchedule.date = converUIDateFormatToCustodianHistoryApiDateFormat(fundSchedule.date);

      if (!fundSchedule.date) {
        return;
      }

      const apiKeys = ["id", "date", "type", "value", "valueTickerId", "note"];

      const apiObject = Object.keys(fundSchedule)
        .filter(key => apiKeys.includes(key) && fundSchedule[key] !== null)
        .reduce((obj, key) => {
          obj[key] = fundSchedule[key];
          return obj;
        }, {});

      dispatch(updateFundScheduleAction(apiObject));

      if (isFirstEdit === true) {
        const request = idempotentId => ApiClient.createCustodianFundSchedule(idempotentId, custodianId, apiObject);
        const syncItem = new SyncItem(SyncItemType.CREATE, fundSchedule.id, request, 0, false, apiData => {});
        dispatch(enqueueItem(syncItem));
      } else {
        const request = idempotentId =>
          ApiClient.updateCustodianFundSchedule(idempotentId, custodianId, fundSchedule.id, apiObject);
        const syncItem = new SyncItem(SyncItemType.UPDATE, fundSchedule.id, request, 1000, true, apiData => {});
        dispatch(enqueueItem(syncItem));
      }
    }
  };
};

export const deleteFundSchedule = (custodianId, fundScheduleId) => {
  return dispatch => {
    dispatch(deleteFundScheduleAction(fundScheduleId));

    const request = idempotentId => ApiClient.deleteCustodianFundSchedule(idempotentId, custodianId, fundScheduleId);
    const syncItem = new SyncItem(SyncItemType.DELETE, fundScheduleId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const moveFundScheduleToCashflow = (type, custodianId, fundScheduleId, onSuccess, onError) => {
  return (dispatch, getState) => {
    const currentPortfolio = currentPortfolioSelector(getState());
    const portfolioTicker = getTickerUsingShortName(currentPortfolio.currency);
    const fundSchedules = fundScheduleSelector(getState(), type);
    const fundSchedule = fundSchedules.find(fundSchedule => fundSchedule.id === fundScheduleId);

    if (!fundSchedule) {
      return onError("Fund schedule not found");
    }

    const fundScheduleCurrency = getTickerUsingId(fundSchedule.valueTickerId).shortName;
    const fundScheduleDate = new Date(fundSchedule.date);
    const exchangeRate = getExchangeRate(fundScheduleCurrency, currentPortfolio.currency, false, fundScheduleDate);

    const moveToCashflow = () => {
      const exchangeRate = getExchangeRate(fundScheduleCurrency, currentPortfolio.currency, false, fundScheduleDate);
      const currentCashflows = custodianDetailsSelector(getState()).cashFlow;
      const cashflowWithSameDate = currentCashflows.find(item => item.date === fundSchedule.date);

      const propertiesToUpdate = cashflowWithSameDate
        ? { ...cashflowWithSameDate }
        : {
            id: fundSchedule.id,
            date: fundSchedule.date,
            note: fundSchedule.note
          };

      propertiesToUpdate.date = getCustodianHistoryFormattedDateString(new Date(propertiesToUpdate.date).getTime());

      if (type === fundScheduleTypes.DISTRIBUTION) {
        propertiesToUpdate.cashOut = fundSchedule.value;
        propertiesToUpdate.cashOutTickerId = fundSchedule.valueTickerId;
        propertiesToUpdate.cashOutExchangeRate = getExchangeRateDetails(
          portfolioTicker.id,
          exchangeRate,
          fundScheduleDate
        );
      } else if (type === fundScheduleTypes.CAPITAL_CALL) {
        propertiesToUpdate.cashIn = fundSchedule.value;
        propertiesToUpdate.cashInTickerId = fundSchedule.valueTickerId;
        propertiesToUpdate.cashInExchangeRate = getExchangeRateDetails(
          portfolioTicker.id,
          exchangeRate,
          fundScheduleDate
        );
      }

      dispatch(
        updateCustodianCashflow(
          !cashflowWithSameDate === true,
          custodianId,
          propertiesToUpdate,
          () => {
            dispatch(deleteFundSchedule(custodianId, fundScheduleId));
            onSuccess();
          },
          error => {
            onError(error.errorMessage);
          }
        )
      );
    };

    if (!exchangeRate) {
      dispatch(
        fetchTickerDetails(
          fundScheduleCurrency,
          fundScheduleDate,
          () => {
            moveToCashflow();
          },
          error => onError(error),
          false
        )
      );
    } else {
      moveToCashflow();
    }
  };
};

export const checkIfACustodianIsCash = custodian => {
  return custodian.type === 2;
};

// Usecase: When custodian is updating and details should be fetched after the custodian is updated
// Added for PVST to manual and vice-versa
// If someone clicks on details immediately on doing above actions it should only fetch details after the above actions are completed
let detailsBlockPromise = new DeferredPromise();
detailsBlockPromise.resolve();

export const getDetailsBlockPromise = () => detailsBlockPromise;

let detailsBlockTimeoutId = null;
export const blockDetailsLoading = () => {
  detailsBlockPromise = new DeferredPromise();

  clearTimeout(detailsBlockTimeoutId);
  detailsBlockTimeoutId = setTimeout(() => {
    detailsBlockPromise.resolve();
  }, 5000);
};

export const unblockDetailsLoading = () => {
  detailsBlockPromise.resolve();
};

////////////////////////////////////////

const eventMethods = { updateCustodianHolding };

eventBus.addEventListener("any", event => {
  const { eventName, data } = event.detail;
  if (eventMethods[eventName]) {
    store.dispatch(eventMethods[eventName].apply(this, data));
  }
});

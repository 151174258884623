import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { SUBSCRIPTION_PLANS, accountPlanSelector, portfoliosSelector, withRouter } from "@kubera/common";
import {
  isMultiuserPendingSelector,
  multiuserListSelector,
  isSharedPortfolioUserSelector,
  userSelector,
  formatDateForLastLogin,
  sendMultiuserReInvite,
  accountMultiuserEnabledSelector,
  maxMultiuserMemberCountSelector,
  deleteSubAccountuser,
  requestAccountDeletion,
  signOut,
  accountSubscriptionStatusSelector,
  SUBSCRIPTION_STATUS,
  getPortfolioSessionUserId,
  sharedPortfolioUsersSelector,
  subAccountUserSelfLeave,
  switchToUsersDefaultAccount,
  currentPortfolioSelector,
  updateMultiuserInvite,
  showBlackPaywallIfQualifies,
  deletePortfolioAction,
  capitalizeStringWithSpaces,
  ADD_USER_ACTION
} from "@kubera/common";
import Loader from "components/loader/Loader";
import AddUserDialog from "./AddUserDialog";
import { ReactComponent as DeleteIcon } from "assets/images/delete_user_icon.svg";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import AccountSettingsComponentExports, {
  accountSettingsTabs
} from "components/account_settings/AccountSettingsComponentExports";
import { modalValues } from "routes";
import DropDown from "components/inputs/DropDown";
import { DialogOverlay } from "components/dialog/DialogOverlay";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 20px;
`;

const ListLoader = styled(Loader)`
  width: 100%;
  height: 100%;
`;

const MemberListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const UneditableMemberDescription = styled.div`
  margin-top: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  opacity: 0.7;
`;

const MemberItem = styled.div`
  display: flex;
  padding: 10px 15px 10px 15px;
  font-style: normal;
  border-bottom: ${({ isLast }) => (!isLast ? "1px solid rgba(0, 0, 0, 0.1)" : null)};
`;

const MemberDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  text-transform: capitalize;
`;

const MemberEmail = styled.div`
  font-weight: 400;
  font-size: 11px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const MemberLastSeen = styled(MemberEmail)`
  margin-top: 3px;
`;

const MemberActions = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  opacity: 0.5;
  text-transform: capitalize;
`;

const ReInviteButton = styled.div`
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  text-transform: capitalize;
  font-feature-settings: "ss01" on, "calt" off;
  cursor: pointer;
  text-decoration: underline;
`;

const PermissionDropDown = styled(DropDown)`
  margin-right: 10px;
  padding-right: 0px;
  text-transform: capitalize;
  background: transparent;
  border: none;
  opacity: ${props => props.disabled === true && "0.5"};
`;

const DeleteUserButton = styled(ReInviteButton)``;

const DeleteSelfButton = styled(ReInviteButton)``;

const AddUserButton = styled.div`
  padding: 15px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  cursor: pointer;
`;

const UpgradeConfirmationDialog = styled(ConfirmationDialog)`
  min-width: 655px;
`;

const DeleteUserConfirmationDialog = styled(ConfirmationDialog)`
  min-width: 700px;
`;

class UserListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddUserDialog: false,
      reInvitedMultiuserIds: [],
      showUpgradeDialog: false,
      showDeleteSubuserConfirmation: false,
      isDeletingUser: false,
      deleteUserErrorMessage: null,
      userToDelete: null,
      showDeleteSelfConfirmation: false,
      isUserLeavingLastPortfolio: false,
      accountDeletionRequestCompleted: false
    };

    this.handleAddUserClick = this.handleAddUserClick.bind(this);
    this.handleAddUserDialogDismiss = this.handleAddUserDialogDismiss.bind(this);
    this.handleReInviteClick = this.handleReInviteClick.bind(this);
    this.handleDeleteUserClick = this.handleDeleteUserClick.bind(this);
    this.handleUpgradeButtonClick = this.handleUpgradeButtonClick.bind(this);
    this.handleUpgradeDialogDismiss = this.handleUpgradeDialogDismiss.bind(this);
    this.handleDeleteUserConfirmationClick = this.handleDeleteUserConfirmationClick.bind(this);
    this.handleDeleteUserConfirmationDismiss = this.handleDeleteUserConfirmationDismiss.bind(this);
    this.handleDeleteSelfClick = this.handleDeleteSelfClick.bind(this);
    this.handleAccountDeletionRequestConfirmationClick = this.handleAccountDeletionRequestConfirmationClick.bind(this);
    this.handlePermissionDropDownSelection = this.handlePermissionDropDownSelection.bind(this);
  }

  handleDeleteSelfClick(e, user) {
    const isUserLeavingLastPortfolio =
      user.allowAll === 0 && this.props.userList.filter(item => item.userId === this.props.currentUser.id).length === 1;
    this.setState({ showDeleteSelfConfirmation: true, userToDelete: user, isUserLeavingLastPortfolio });
  }

  handleDeleteUserClick(e, user) {
    this.setState({ showDeleteSubuserConfirmation: true, userToDelete: user });
  }

  handleReInviteClick(e, user) {
    const reInvitedMultiuserIds = this.state.reInvitedMultiuserIds;
    reInvitedMultiuserIds.push(user.id);

    this.setState({ reInvitedMultiuserIds: reInvitedMultiuserIds });
    this.props.sendMultiuserReInvite(user.id);
  }

  async handleAddUserClick(e) {
    if (this.props.access.portfolioId) {
      let allowUserUpdate = true;
      allowUserUpdate = await this.props.showBlackPaywallIfQualifies(ADD_USER_ACTION);

      if (!allowUserUpdate) return;
    }

    if (this.props.isMultiuserEnabled === false) {
      this.setState({ showUpgradeDialog: true });
    } else {
      this.setState({ showAddUserDialog: true });
    }
  }

  handleAddUserDialogDismiss() {
    this.setState({ showAddUserDialog: false });
  }

  handleUpgradeDialogDismiss() {
    this.setState({ showUpgradeDialog: false });
  }

  handleUpgradeButtonClick(e) {
    AccountSettingsComponentExports.show(
      this.props.history,
      this.props.location,
      accountSettingsTabs.SUBSCRIPTION,
      modalValues.SUBSCRIPTION_EDIT
    );
  }

  handleDeleteUserConfirmationDismiss() {
    this.setState({
      showDeleteSelfConfirmation: false,
      showDeleteSubuserConfirmation: false,
      userToDelete: null,
      isUserLeavingLastPortfolio: false
    });
  }

  handleDeleteUserConfirmationClick(e) {
    this.setState({ isDeletingUser: true });

    if (this.state.userToDelete.userId === this.props.currentUser.id) {
      this.props.subAccountUserSelfLeave(
        this.state.userToDelete.allowAll === 0 ? this.state.userToDelete.portfolioId : undefined,
        () => {
          if (this.state.userToDelete.allowAll === 0) {
            if (this.state.isUserLeavingLastPortfolio) {
              switchToUsersDefaultAccount();
            } else {
              this.props.deleteLocalPortfolio(this.props.currentPortfolio);
              DialogOverlay.forceDismiss(this.props.history, this.props.location);
            }
          } else {
            switchToUsersDefaultAccount();
          }
        },
        error => {
          this.setState({ isDeletingUser: false, deleteUserErrorMessage: error.errorMessage });
        }
      );
    } else {
      this.props.deleteSubAccountuser(
        this.state.userToDelete.id,
        () => {
          this.setState({ showDeleteSubuserConfirmation: false, isDeletingUser: false });
        },
        error => {
          this.setState({ isDeletingUser: false, deleteUserErrorMessage: error.errorMessage });
        }
      );
    }
  }

  handleAccountDeletionRequestConfirmationClick(e) {
    signOut();
  }

  getOrderedUserList() {
    if (!this.props.userList === true) {
      return [];
    }
    const owner = this.props.userList.find(item => this.isUserOwner(item) === true);
    var subAccounts = this.props.userList.filter(item => this.isUserOwner(item) === false);
    if (!this.props.access === false) {
      if (this.props.access.allowAll === 1) {
        subAccounts = subAccounts.filter(item => item.allowAll === 1);
      } else {
        if (this.props.currentPortfolio.write === 0) {
          subAccounts = subAccounts.filter(
            item => item.userId === this.props.currentUser.id && item.portfolioId === this.props.currentPortfolio.id
          );
        } else {
          subAccounts = subAccounts.filter(
            item => item.portfolioId === this.props.currentPortfolio.id || !item.portfolioId === true
          );
        }
      }
    }
    subAccounts = subAccounts.sort((a, b) => a.tsInvite - b.tsInvite);
    if (owner) {
      const currentUser = subAccounts.find(item => item.userId === this.props.currentUser.id);
      subAccounts = [owner, ...subAccounts.filter(item => item.userId !== this.props.currentUser.id)];
      if (currentUser) {
        subAccounts.splice(1, 0, currentUser);
      }
    }
    return subAccounts;
  }

  isUserOwner(user) {
    if (!getPortfolioSessionUserId() === true) {
      return user.userId === this.props.currentUser.id;
    }
    return user.userId === getPortfolioSessionUserId() || user.id === getPortfolioSessionUserId();
  }

  hasUneditableUsers(orderedUserList) {
    if (this.props.access && this.props.access.allowAll === 1) {
      return false;
    }
    return (
      orderedUserList.filter(
        user => this.isUserOwner(user) === false && user.userId !== this.props.currentUser.id && user.allowAll === 1
      ).length > 0
    );
  }

  getFormattedUserName(user) {
    if (this.props.currentUser.id === user.userId) {
      return `${this.props.currentUser.name} (${i18n.t("you")})`;
    }
    var name = user.name;
    if (
      this.props.access &&
      this.props.access.allowAll === 0 &&
      user.allowAll === 1 &&
      this.isUserOwner(user) === false
    ) {
      name = `${user.name}*`;
    }
    if (!user.tsLastAccess === true) {
      return `${name} (${i18n.t("invited")})`;
    }
    return name;
  }

  getUserLastSeenString(user) {
    if (!user.tsLastAccess === false) {
      return `${i18n.t("lastLogin")}: ${formatDateForLastLogin(user.tsLastAccess)}`;
    }
    return `${i18n.t("invited")}: ${formatDateForLastLogin(user.tsReInvite || user.tsInvite)}`;
  }

  shouldShowAddButton() {
    if (this.props.isSharedPortfolioAccountUser && this.props.accountPlan === SUBSCRIPTION_PLANS.YEARLY) {
      return false;
    }
    if (this.props.accountSubscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
      return false;
    }
    if (this.props.access && this.props.access.allowAll === 1 && this.props.isSharedPortfolioAccountUser) {
      return false;
    }
    if (this.props.access && this.props.access.allowAll === 0 && this.props.currentPortfolio.write === 0) {
      return false;
    }
    if (!this.props.maxMultiusers === false) {
      return this.props.userList && this.props.userList.length < this.props.maxMultiusers + 1;
    }
    return true;
  }

  shouldShowDeleteOthersButton(user) {
    if (this.isUserOwner(user) || user.userId === this.props.currentUser.id) {
      return false;
    }
    if (this.props.access && this.props.access.allowAll === 1 && this.props.isSharedPortfolioAccountUser === true) {
      return false;
    }
    if (
      this.props.access &&
      this.props.access.allowAll === 0 &&
      (user.allowAll === 1 || this.props.currentPortfolio.write === 0)
    ) {
      return false;
    }
    return true;
  }

  handlePermissionDropDownSelection(item, user) {
    this.props.updateMultiuserInvite(user, { write: item.id });
  }

  isPermissionDropDownDisabled(user) {
    if (this.props.currentUser.id === user.userId) {
      return true;
    }
    if (this.props.access && this.props.access.allowAll === 1 && this.props.isSharedPortfolioAccountUser) {
      return true;
    }
    if (this.props.access && this.props.access.allowAll === 0) {
      return user.allowAll === 1 || this.props.currentPortfolio.write === 0;
    }
    return false;
  }

  getPermissionDropDownData(user) {
    const writeEnabled = this.getOrderedUserList().find(item => item.id === user.id).write ? 1 : 0;
    return [
      {
        id: 1,
        label: i18n.t("addUserPermission.writable"),
        selected: writeEnabled === 1
      },
      {
        id: 0,
        label: i18n.t("addUserPermission.readOnly"),
        selected: writeEnabled === 0
      }
    ];
  }

  render() {
    if (this.props.isPending === true && !this.props.userList === true) {
      return <ListLoader />;
    }

    const orderedUserList = this.getOrderedUserList();
    const hasUneditableUsers = this.hasUneditableUsers(orderedUserList);
    const portfolioCollaborationPage = this.props.access && this.props.access.allowAll === 0;
    return (
      <Container className={this.props.className}>
        <MemberListContainer>
          <MemberList>
            {orderedUserList.map((user, index) => {
              return (
                <MemberItem key={index} isLast={index === orderedUserList.length - 1 && !this.shouldShowAddButton()}>
                  <MemberDetails>
                    <MemberName>{this.getFormattedUserName(user)}</MemberName>
                    <MemberEmail>{user.email}</MemberEmail>
                    {(this.props.isSharedPortfolioAccountUser === false || this.isUserOwner(user) === false) && (
                      <MemberLastSeen>{this.getUserLastSeenString(user)}</MemberLastSeen>
                    )}
                  </MemberDetails>
                  <MemberActions>
                    {this.isUserOwner(user) === true && <OwnerLabel>{i18n.t("accountOwner")}</OwnerLabel>}
                    {this.props.isSharedPortfolioAccountUser === false &&
                      this.isUserOwner(user) === false &&
                      !user.tsLastAccess === true &&
                      this.state.reInvitedMultiuserIds.includes(user.id) === false &&
                      (portfolioCollaborationPage ? user.allowAll === 0 : true) && (
                        <ReInviteButton onClick={e => this.handleReInviteClick(e, user)}>
                          {i18n.t("reInvite")}
                        </ReInviteButton>
                      )}
                    {this.props.isSharedPortfolioAccountUser === true &&
                      this.props.currentUser.id === user.userId &&
                      (this.props.access && (this.props.access.allowAll === 1 || user.allowAll !== 1)) && (
                        <DeleteSelfButton onClick={e => this.handleDeleteSelfClick(e, user)}>
                          {user.allowAll === 1 ? i18n.t("deleteSubuserSelf") : i18n.t("deleteSubuserPortfolio")}
                        </DeleteSelfButton>
                      )}
                    {this.isUserOwner(user) === false && (
                      <PermissionDropDown
                        disabled={this.isPermissionDropDownDisabled(user)}
                        hideIndicatorForDisabled={true}
                        width={180}
                        items={this.getPermissionDropDownData(user)}
                        onSelection={item => this.handlePermissionDropDownSelection(item, user)}
                      />
                    )}
                    {this.shouldShowDeleteOthersButton(user) && (
                      <DeleteUserButton onClick={e => this.handleDeleteUserClick(e, user)}>
                        <DeleteIcon />
                      </DeleteUserButton>
                    )}
                  </MemberActions>
                </MemberItem>
              );
            })}
            {this.shouldShowAddButton() === true && (
              <AddUserButton onClick={this.handleAddUserClick}>{i18n.t("addUser")}</AddUserButton>
            )}
          </MemberList>
        </MemberListContainer>
        {hasUneditableUsers === true && (
          <UneditableMemberDescription
            dangerouslySetInnerHTML={{
              __html: i18n
                .t("uneditableMemberDescription")
                .replace("%s%", `${process.env.PUBLIC_URL}#modal=account_settings&tab=multi_user`)
            }}
          ></UneditableMemberDescription>
        )}
        {this.state.showAddUserDialog === true && (
          <AddUserDialog
            title={this.props.addUserTitle}
            access={this.props.access}
            onDismiss={this.handleAddUserDialogDismiss}
          />
        )}
        {this.state.showUpgradeDialog === true && (
          <UpgradeConfirmationDialog
            title={i18n.t("multiuserUpgradeDialog.title")}
            description={i18n.t("multiuserUpgradeDialog.description")}
            positiveButtonTitle={i18n.t("multiuserUpgradeDialog.action")}
            handlePositiveButtonClick={this.handleUpgradeButtonClick}
            canUserDismiss={true}
            onDismiss={this.handleUpgradeDialogDismiss}
          />
        )}
        {this.state.showDeleteSubuserConfirmation === true && (
          <DeleteUserConfirmationDialog
            title={
              this.state.userToDelete.allowAll === 0
                ? i18n.t("deletePortfolioSubuserDialog.title")
                : i18n.t("deleteSubuserDialog.title")
            }
            description={
              this.state.userToDelete.allowAll === 0
                ? i18n
                    .t("deletePortfolioSubuserDialog.description")
                    .replace("%s%", capitalizeStringWithSpaces(this.state.userToDelete.name))
                : i18n.t("deleteSubuserDialog.description")
            }
            positiveButtonTitle={
              this.state.userToDelete.allowAll === 0 ? i18n.t("remove") : i18n.t("deleteSubuserDialog.action")
            }
            negativeButtonTitle={i18n.t("cancel")}
            handlePositiveButtonClick={this.handleDeleteUserConfirmationClick}
            handleNegativeButtonClick={this.handleDeleteUserConfirmationDismiss}
            canUserDismiss={this.state.isDeletingUser === false}
            isLoading={this.state.isDeletingUser}
            errorMessage={this.state.deleteUserErrorMessage}
            onDismiss={this.handleDeleteUserConfirmationDismiss}
          />
        )}
        {this.state.showDeleteSelfConfirmation === true && (
          <DeleteUserConfirmationDialog
            title={
              this.state.userToDelete.allowAll === 0
                ? i18n.t("deletePortfolioSubuserSelfDialog.title")
                : i18n.t("deleteSubuserSelfDialog.title")
            }
            description={
              this.state.userToDelete.allowAll === 0
                ? this.state.isUserLeavingLastPortfolio === true
                  ? i18n.t("deleteLastPortfolioSubuserSelfDialog.description")
                  : i18n.t("deletePortfolioSubuserSelfDialog.description")
                : i18n.t("deleteSubuserSelfDialog.description")
            }
            positiveButtonTitle={i18n.t("deleteSubuserSelfDialog.action")}
            negativeButtonTitle={i18n.t("cancel")}
            handlePositiveButtonClick={this.handleDeleteUserConfirmationClick}
            handleNegativeButtonClick={this.handleDeleteUserConfirmationDismiss}
            canUserDismiss={this.state.isDeletingUser === false}
            isLoading={this.state.isDeletingUser}
            errorMessage={this.state.deleteUserErrorMessage}
            onDismiss={this.handleDeleteUserConfirmationDismiss}
          />
        )}
        {this.state.accountDeletionRequestCompleted === true && (
          <DeleteUserConfirmationDialog
            title={i18n.t("deleteAccountConfirmationDialogTitle")}
            description={i18n.t("deleteAccountConfirmationDialogDescription")}
            positiveButtonTitle={i18n.t("signOut")}
            canUserDismiss={this.state.isDeletingUser === false && this.state.accountDeletionRequestCompleted === false}
            isLoading={this.state.isDeletingUser}
            errorMessage={this.state.deleteUserErrorMessage}
            handlePositiveButtonClick={this.handleAccountDeletionRequestConfirmationClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  accountSubscriptionStatus: accountSubscriptionStatusSelector(state),
  currentUser: userSelector(state),
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state),
  isListPending: isMultiuserPendingSelector(state),
  userList: multiuserListSelector(state),
  isMultiuserEnabled: accountMultiuserEnabledSelector(state),
  maxMultiusers: maxMultiuserMemberCountSelector(state),
  sharedPortfolioUsers: sharedPortfolioUsersSelector(state),
  currentPortfolio: currentPortfolioSelector(state),
  accountPlan: accountPlanSelector(state),
  portfolios: portfoliosSelector(state)
});

const mapDispatchToProps = {
  sendMultiuserReInvite,
  deleteSubAccountuser,
  requestAccountDeletion,
  subAccountUserSelfLeave,
  updateMultiuserInvite,
  showBlackPaywallIfQualifies,
  deleteLocalPortfolio: deletePortfolioAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserListComponent));

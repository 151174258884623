import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import { useLocation } from "react-router-dom";
import { hashParams } from "routes";
import {
  exportAndDelete,
  signOut,
  userObjectSelector,
  getHashParams,
  siteConfigSelector,
  isSharedPortfolioUserSelector,
  getAccountOwnerNameSelector,
  fetchUser,
  useHistory,
  isMobile,
  sharedPortfolioAccountTitleSelector,
  subAccountUserSelfLeave,
  switchToUsersDefaultAccount,
  sharedPortfolioUsersSelector
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import AccountSettingsComponentExports, {
  accountSettingsTabs
} from "components/account_settings/AccountSettingsComponentExports";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import Spinner from "components/loader/Spinner";
import SwitchAccountComponent from "components/multiuser/SwitchAccountComponent";
import useShowPaywall from "./useShowPaywall";

const isMobileDevice = isMobile();

const Container = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;

  & > div {
    padding: 0 36px;
  }

  * {
    box-sizing: border-box;
  }
`;

const PaywallDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 350px;
  margin-top: 163px;
  padding: 40px 30px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const UserDetails = styled.div`
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: bold;
`;

const Desc = styled.div`
  font-size: 14px;
  line-height: ${isMobileDevice ? "21px" : "150%"};
  margin-bottom: 60px;
  margin-top: ${isMobileDevice ? "-3px" : null};
`;

const SubscribeBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-bottom: 30px;
  font-size: 14px;
  background: ${props => (props.theme.mode === "default" ? null : "#676767")};
`;

const ExportDelUser = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 110%;
`;

const SwitchAccountButton = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 110%;
  margin-top: 30px;
`;

const DeleteConfirmationDialog = styled(ConfirmationDialog)`
  width: 100%;
  white-space: pre-wrap;
  box-sizing: border-box;
  padding: 32px 25px 43px;

  * {
    box-sizing: border-box;
  }
`;

const DeleteLoader = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const Link = styled.a`
  color: ${props => (isMobileDevice ? "#000" : props.theme.linkColor)};
`;

const BlockerPaywall = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userObject = useSelector(userObjectSelector);
  const siteConfig = useSelector(siteConfigSelector);
  const isSharedPortfolioAccountUser = useSelector(isSharedPortfolioUserSelector);
  const getAccountOwnerName = useSelector(getAccountOwnerNameSelector);
  const sharedPortfolioUsers = useSelector(sharedPortfolioUsersSelector);

  const [step, setStep] = useState(0);
  const [delDownloadLoading, setDelDownloadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingSubAccountCheck, setIsLoadingSubAccountCheck] = useState(false);
  const isPaywallVisible = useShowPaywall();

  const urlHashParams = getHashParams(location);
  const modalValue = urlHashParams[hashParams.MODAL];
  const sharedPortfolioAccountTitle = useSelector(sharedPortfolioAccountTitleSelector);

  const showSubscriptionModal = () => {
    AccountSettingsComponentExports.show(history, location, accountSettingsTabs.SUBSCRIPTION);
  };

  const [showSwitchDialog, setShowSwitchDialog] = useState(false);
  const [showLeaveConfirmationDialog, setShowLeaveConfirmationDialog] = useState(false);

  const checkSubscription = () => {
    setIsLoadingSubAccountCheck(true);
    dispatch(
      fetchUser(
        () => {
          setIsLoadingSubAccountCheck(false);
        },
        () => {
          setIsLoadingSubAccountCheck(false);
        }
      )
    );
  };

  const exportAndDeleteUser = async () => {
    setDelDownloadLoading(true);
    await dispatch(exportAndDelete()).catch(error => {
      setErrorMessage(error.errorMessage || i18n.t("genericError"));
    });
    setDelDownloadLoading(false);
    setStep(1);
  };

  const leaveAccount = () => {
    setDelDownloadLoading(true);
    dispatch(
      subAccountUserSelfLeave(
        undefined,
        () => {
          switchToUsersDefaultAccount();
        },
        error => {
          setErrorMessage(error.errorMessage || i18n.t("genericError"));
        }
      )
    );
  };

  const onDismiss = () => {
    setStep(0);
    setErrorMessage(null);
  };

  const onSignOut = () => {
    signOut();
  };

  const siteName = siteConfig ? siteConfig.name : "";

  useEffect(() => {
    if (isPaywallVisible || errorMessage) {
      history.push({
        ...location,
        hash: ``
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorMessage) {
    return (
      <Container onDismiss={onDismiss}>
        <PaywallDialog>
          <Title>{i18n.t("paywall.errorTitle")}</Title>
          <Desc>{i18n.t("paywall.errorDesc")}</Desc>
          <PrimaryButton title={i18n.t("ok")} onClick={onDismiss} />
        </PaywallDialog>
      </Container>
    );
  }

  if (step === 1) {
    return (
      <DeleteConfirmationDialog
        title={i18n.t("paywall.mailSentTitle")}
        description={i18n.t("paywall.mailSentDesc").replace(/%s%/g, siteName)}
        positiveButtonTitle={i18n.t("signOut")}
        negativeButtonTitle={i18n.t("goBackLink")}
        isLoading={delDownloadLoading}
        handlePositiveButtonClick={onSignOut}
        handleNegativeButtonClick={onDismiss}
        onDismiss={onDismiss}
        canUserDismiss
      />
    );
  }

  if (!userObject) {
    return null;
  }

  if (isPaywallVisible && !modalValue) {
    if (isSharedPortfolioAccountUser) {
      return (
        <Container onDismiss={onDismiss}>
          <PaywallDialog>
            <Title>{i18n.t("paywall.isSubAccount.modaltitle")}</Title>
            <UserDetails>{sharedPortfolioAccountTitle}</UserDetails>
            <Desc>
              {`${i18n.t("paywall.isSubAccount.modaldesc").replace(/%s%/g, getAccountOwnerName)}`}
              <Link href="mailto:hello@kubera.com">hello@kubera.com</Link>
            </Desc>
            <SubscribeBtn title={i18n.t("refresh")} onClick={checkSubscription} isLoading={isLoadingSubAccountCheck} />
            {!delDownloadLoading ? (
              <ExportDelUser onClick={e => setShowLeaveConfirmationDialog(true)}>
                {i18n.t("paywall.isSubAccount.leaveAccount")}
              </ExportDelUser>
            ) : (
              <DeleteLoader />
            )}
            <SwitchAccountButton onClick={e => setShowSwitchDialog(true)}>
              {i18n.t("switchAccount")}
            </SwitchAccountButton>
            {showSwitchDialog === true && <SwitchAccountComponent onDismiss={() => setShowSwitchDialog(false)} />}
            {showLeaveConfirmationDialog && (
              <ConfirmationDialog
                title={i18n.t("deleteSubuserSelfDialog.title")}
                description={i18n.t("deleteSubuserSelfDialog.description")}
                positiveButtonTitle={i18n.t("deleteSubuserSelfDialog.action")}
                negativeButtonTitle={i18n.t("cancel")}
                handlePositiveButtonClick={leaveAccount}
                handleNegativeButtonClick={() => setShowLeaveConfirmationDialog(false)}
                canUserDismiss={delDownloadLoading === false}
                isLoading={delDownloadLoading}
                errorMessage={errorMessage}
                onDismiss={() => setShowLeaveConfirmationDialog(false)}
              />
            )}
          </PaywallDialog>
        </Container>
      );
    }

    return (
      <Container onDismiss={onDismiss}>
        <PaywallDialog>
          <Title>{i18n.t("paywall.modaltitle").replace(/%s%/g, siteName)}</Title>
          {!isMobileDevice && <UserDetails>{`${userObject.name} (${userObject.email})`}</UserDetails>}
          <Desc>
            {`${i18n.t(isMobileDevice ? "pawall.mobile.modaldesc" : "paywall.modaldesc").replace(/%s%/g, siteName)}`}
            <Link href="mailto:hello@kubera.com">hello@kubera.com</Link>
          </Desc>
          <SubscribeBtn title={i18n.t("subscribe")} onClick={showSubscriptionModal} />

          {sharedPortfolioUsers.length > 0 ? (
            <SwitchAccountButton onClick={e => setShowSwitchDialog(true)}>
              {i18n.t("switchAccount")}
            </SwitchAccountButton>
          ) : !delDownloadLoading ? (
            <ExportDelUser onClick={exportAndDeleteUser}>{i18n.t("paywall.downloaddel")}</ExportDelUser>
          ) : (
            <DeleteLoader />
          )}
          {showSwitchDialog === true && <SwitchAccountComponent onDismiss={() => setShowSwitchDialog(false)} />}
        </PaywallDialog>
      </Container>
    );
  }

  return null;
};

export default BlockerPaywall;

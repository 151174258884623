import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import { useLocation } from "react-router-dom";
import { routes, hashParams, modalValues } from "routes";
import {
  currentPortfolioSelector,
  currentPortfolioNetWorthDataSelector,
  convertCurrency,
  shortFormatNumberWithCurrency,
  getNetWorthChartStartDateForPortfolio,
  getTickerUsingId,
  portfolioTotalForAssets,
  portfolioTotalForDebts,
  portfolioUnfundedCapital,
  getChangeTotalsWithContributorsForCurrentPortfolio,
  chartTimeRange,
  supportedIndicesSelector,
  supportedIndicesIrrSelector,
  irrComparisonIndexesSelector,
  isAppInWhiteLabelMode,
  sanitizeIrr,
  pastValueInterval,
  userPreferencesSelector,
  updateUserPreferences,
  getUnfundedCommitmentForCustodianReselectFn,
  getMonthFromDate,
  getHashParams,
  netWorthIrrComparisonTimePeriodSelector,
  getCagr,
  getNetWorthChartTimeRangeForPortfolio,
  siteConfigSelector,
  recapDataSelector,
  getSymbolForTickerUsingShortName,
  userMaskAllValuesSelector,
  portfolioTaxOnUnrealizedGains,
  getEstimatedTaxOnUnrealizedGainsForCustodian,
  isUnfundedCardHiddenForAPortfolio,
  store,
  getUnrealizedGainForACustodianReselectFn,
  categoryType,
  getDateInKuberaFormat,
  useHistory,
  recapDataTaxOnUnrealisedGainsSelector,
  recapDataCustodiansWithCommitedCapitalSelector,
  recapDataCashOnHandCustodiansSelector,
  recapDataCashOnHandTotalSelector,
  recapDataTotalInvestableAssetsValueSelector,
  calcCustodianOwnershipValue
} from "@kubera/common";
import { useTheme } from "theme";
import ScrollCounter from "components/scroll_counter/ScrollCounter";
import ClickableLink from "components/labels/DelayLink";
import slideAnimation from "utilities/slideAnimation";
import { category } from "components/dashboard/DashboardComponentExports";
import { ReactComponent as RightArrow } from "assets/images/right_arrow.svg";
import ChangeLabel from "components/labels/ChangeLabel";
import { timePeriods } from "components/net_worth/PortfolioIrrDialog";
import PercentageLabel from "components/labels/PercentageLabel";
import MobileCustodianListDialog from "components/net_worth/MobileCustodianListDialog";
import LineChartComponent from "components/charts/LineChartComponent";

const FixedContainer = styled.div`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.mobileBackground};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  animation: ${slideAnimation};
`;

const NavigationSection = styled.div`
  width: 100%;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: ${props => `1px solid ${props.theme.mobileSeperatorColor}`};
`;

const BottomSection = styled(NavigationSection)`
  border: 0;
`;

const NavigationLink = styled(ClickableLink)`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
  text-decoration: none;
  user-select: none;
`;

const AssetsNavigationLink = styled(NavigationLink)`
  margin-top: 20px;
`;

const RightArrowIcon = styled(RightArrow)`
  width: 7px;
  height: 12px;
  margin-left: 6px;
  margin-top: 8px;

  path {
    fill: ${props => props.theme.mobileTxtColorChangeArrow};
  }
`;

const NavigationTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtColor};
`;

const NavigationTotal = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on, "calt" off;
  color: ${props => props.theme.mobileTxtColor};
`;

const NetWorthNavigationTotal = styled(NavigationTotal)`
  font-weight: 700;
`;

const NavigationTotalAnimate = styled(ScrollCounter)`
  font-weight: 700;
  height: 44px;
`;

const ChangeNavigationLink = styled(NavigationLink)`
  margin-top: 18px;
  filter: ${({ isPlaceholder }) => (isPlaceholder === true ? "opacity(0.3)" : null)};
`;

const ChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
`;

const NetWorthChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
`;

const CagrTitle = styled.div`
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColorChangeTitle};
`;

const CagrContainer = styled.div`
  display: flex;
`;

const CagrDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  filter: ${({ isPlaceholder }) => (isPlaceholder === true ? "opacity(0.3)" : null)};
`;

const Cagr = styled(PercentageLabel)`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: "ss01" on, "calt" off;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : null)};
  display: flex;
`;

const ChangeTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  margin-bottom: 5px;
  color: ${props => props.theme.mobileTxtColor};
`;

const ChangeValue = styled(ChangeLabel)`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: "ss01" on;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : null)};
`;

const IndexesIrrContainer = styled.div`
  display: flex;
  margin-top: 18px;
`;

const IndexesIrrDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  filter: ${props => (props.isPlaceholder === true ? "opacity(0.3)" : null)};
`;

const IndexIrrValue = styled(PercentageLabel)`
  width: fit-content;
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: "ss01" on;
`;

const LineChartContainer = styled.div`
  position: relative;
  bottom: 5px;
  padding-bottom: 25px;
  left: 0;
  width: 100%;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};

  &::before {
    content: " ";
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 30px;
    width: 100%;
    background: ${props => props.theme.chartBgColor};
  }
`;

const LineChart = styled(LineChartComponent)`
  min-height: 154px;
  z-index: 1;
  contain: layout;
`;

const PlaceholderPercentage = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${props => props.theme.mobileTxtColor};
`;

const PlaceHolderValue = styled.div`
  font-weight: 400;
  height: 44px;
  filter: opacity(0.3);
`;

const getCurrentCategory = pathName => {
  if (pathName.includes(routes.ASSETS)) {
    return category.ASSET;
  } else if (pathName.includes(routes.DEBTS)) {
    return category.DEBT;
  } else if (pathName.includes(routes.INSURANCE)) {
    return category.INSURANCE;
  }
  return null;
};

const MobileNavComponent = ({ direction }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const userPreferences = useSelector(userPreferencesSelector);
  const siteConfig = useSelector(siteConfigSelector);
  const useMaskedValues = useSelector(userMaskAllValuesSelector);
  const getUnfundedCommitmentForCustodian = useSelector(getUnfundedCommitmentForCustodianReselectFn);

  const assetsTotal = useSelector(portfolioTotalForAssets);
  const assetsChangeTotalsAndContributors = useSelector(state =>
    getChangeTotalsWithContributorsForCurrentPortfolio(state, category.ASSET)
  );
  const debtsTotal = useSelector(portfolioTotalForDebts);
  const debtsChangeTotalsAndContributors = useSelector(state =>
    getChangeTotalsWithContributorsForCurrentPortfolio(state, category.DEBT)
  );

  const selectedCagrTimePeriod = useSelector(netWorthIrrComparisonTimePeriodSelector) || timePeriods.START_DATE;
  const netWorthChartTimeRange = useSelector(getNetWorthChartTimeRangeForPortfolio);
  const netWorth = assetsTotal - debtsTotal;
  const networthData = useSelector(currentPortfolioNetWorthDataSelector);
  const portfolio = useSelector(currentPortfolioSelector);
  const netWorthChartStartDate = useSelector(getNetWorthChartStartDateForPortfolio);
  const hasNetworthData = !networthData === false && !networthData.data === false;

  const investableAssets = useSelector(recapDataTotalInvestableAssetsValueSelector);
  const totalCashOnHand = useSelector(recapDataCashOnHandTotalSelector);
  const totalUnfundedCapital = useSelector(portfolioUnfundedCapital);
  const totalTaxOnUnrealizedGains = useSelector(portfolioTaxOnUnrealizedGains);

  const recapData = useSelector(recapDataSelector);

  const getChangeLabels = (changeTotalsAndContributors, disableTotalChangeColor, pathname) => {
    const getWeekOrMonthlyOrYearlyChangelabel = () => {
      if (
        changeTotalsAndContributors.changes.year.isInsidePortfolioStartDate === true &&
        !changeTotalsAndContributors.currentTotal === false &&
        (!changeTotalsAndContributors.changes.year.total === false ||
          (portfolio.details.changeData && portfolio.details.changeData.isAllChangeFetching === true))
      ) {
        const isUpdating =
          !changeTotalsAndContributors.changes.year.total === false && portfolio.details.changeData.isAllChangeFetching;
        return (
          <ChangeNavigationLink
            to={{
              pathname: routes.MOBILENAV,
              hash: `#${hashParams.MODAL}=${modalValues.GRID_CHANGE}&${hashParams.TAB}=${pastValueInterval.YEAR}&${
                hashParams.CATEGORY
              }=${getCurrentCategory(pathname)}`
            }}
          >
            <ChangeValue
              currency={portfolio.currency}
              startValue={changeTotalsAndContributors.changes.year.total}
              endValue={changeTotalsAndContributors.currentTotal}
              isUpdating={isUpdating}
              placeholder={
                !changeTotalsAndContributors.changes.year.total === true &&
                portfolio.details.changeData.isAllChangeFetching
              }
              isAnimationAllowed={false}
              alignPosition="left"
              disableColor={disableTotalChangeColor}
            >
              {` ${i18n.t("yearChange")} `}
              <RightArrowIcon />
            </ChangeValue>
          </ChangeNavigationLink>
        );
      } else if (
        changeTotalsAndContributors.changes.month.isInsidePortfolioStartDate === true &&
        !changeTotalsAndContributors.currentTotal === false &&
        (!changeTotalsAndContributors.changes.month.total === false ||
          (portfolio.details.changeData && portfolio.details.changeData.isAllChangeFetching === true))
      ) {
        const isUpdating =
          !changeTotalsAndContributors.changes.month.total === false &&
          portfolio.details.changeData.isAllChangeFetching;
        return (
          <ChangeNavigationLink
            to={{
              pathname: routes.MOBILENAV,
              hash: `#${hashParams.MODAL}=${modalValues.GRID_CHANGE}&${hashParams.TAB}=${pastValueInterval.MONTH}&${
                hashParams.CATEGORY
              }=${getCurrentCategory(pathname)}`
            }}
          >
            <ChangeValue
              currency={portfolio.currency}
              startValue={changeTotalsAndContributors.changes.month.total}
              endValue={changeTotalsAndContributors.currentTotal}
              disableColor={disableTotalChangeColor}
              isUpdating={isUpdating}
              placeholder={
                !changeTotalsAndContributors.changes.month.total === true &&
                portfolio.details.changeData.isAllChangeFetching
              }
              isAnimationAllowed={false}
              alignPosition="left"
            >
              {` ${i18n.t("monthChange")} `}
              <RightArrowIcon />
            </ChangeValue>
          </ChangeNavigationLink>
        );
      } else if (
        changeTotalsAndContributors.changes.week.isInsidePortfolioStartDate === true &&
        !changeTotalsAndContributors.currentTotal === false &&
        (!changeTotalsAndContributors.changes.week.total === false ||
          (portfolio.details.changeData && portfolio.details.changeData.isAllChangeFetching === true))
      ) {
        const isUpdating =
          !changeTotalsAndContributors.changes.week.total === false && portfolio.details.changeData.isAllChangeFetching;
        return (
          <ChangeNavigationLink
            to={{
              pathname: routes.MOBILENAV,
              hash: `#${hashParams.MODAL}=${modalValues.GRID_CHANGE}&${hashParams.TAB}=${pastValueInterval.WEEK}&${
                hashParams.CATEGORY
              }=${getCurrentCategory(pathname)}`
            }}
          >
            <ChangeValue
              currency={portfolio.currency}
              startValue={changeTotalsAndContributors.changes.week.total}
              endValue={changeTotalsAndContributors.currentTotal}
              disableColor={disableTotalChangeColor}
              isUpdating={isUpdating}
              placeholder={
                !changeTotalsAndContributors.changes.week.total === true &&
                portfolio.details.changeData.isAllChangeFetching
              }
              isAnimationAllowed={false}
              alignPosition="left"
            >
              {` ${i18n.t("weekChange")} `}
              <RightArrowIcon />
            </ChangeValue>
          </ChangeNavigationLink>
        );
      } else {
        return (
          <ChangeNavigationLink isPlaceholder={true} to={null} direction={null}>
            <PlaceholderPercentage>
              {`+${getSymbolForTickerUsingShortName(portfolio.currency)}XXXX (XX%)`}
              {` ${i18n.t("weekChange")} `}
            </PlaceholderPercentage>
          </ChangeNavigationLink>
        );
      }
    };

    return (
      <ChangeContainer>
        {pathname !== routes.DEBTS &&
          (changeTotalsAndContributors.changes.day.isInsidePortfolioStartDate === true &&
          !changeTotalsAndContributors.currentTotal === false ? (
            <ChangeNavigationLink
              to={{
                pathname: routes.MOBILENAV,
                hash: `#${hashParams.MODAL}=${modalValues.GRID_CHANGE}&${hashParams.TAB}=${pastValueInterval.DAY}&${
                  hashParams.CATEGORY
                }=${getCurrentCategory(pathname)}`
              }}
            >
              <ChangeValue
                currency={portfolio.currency}
                startValue={changeTotalsAndContributors.changes.day.total}
                endValue={changeTotalsAndContributors.currentTotal}
                placeholder={
                  !changeTotalsAndContributors.changes.day.total === true &&
                  portfolio.details.changeData &&
                  portfolio.details.changeData.isDayChangeFetching
                }
                isUpdating={
                  !changeTotalsAndContributors.changes.day.total === false &&
                  portfolio.details.changeData &&
                  portfolio.details.changeData.isDayChangeFetching
                }
                isAnimationAllowed={false}
                alignPosition="left"
                disableColor={disableTotalChangeColor}
              >
                {` ${i18n.t("dayChange")} `}
                <RightArrowIcon />
              </ChangeValue>
            </ChangeNavigationLink>
          ) : (
            <ChangeNavigationLink isPlaceholder={true} to={null} direction={null}>
              <PlaceholderPercentage>
                {`+${getSymbolForTickerUsingShortName(portfolio.currency)}XXXX (XX%)`}
                {` ${i18n.t("dayChange")} `}
              </PlaceholderPercentage>
            </ChangeNavigationLink>
          ))}
        {getWeekOrMonthlyOrYearlyChangelabel()}
      </ChangeContainer>
    );
  };

  const getChartRangeFromTimePeriod = timePeriod => {
    if (selectedCagrTimePeriod === timePeriods.START_DATE) {
      return chartTimeRange.ALL;
    } else if (selectedCagrTimePeriod === timePeriods.YEAR) {
      return chartTimeRange.YEARLY;
    } else if (selectedCagrTimePeriod === timePeriods.YTD) {
      return chartTimeRange.YTD;
    }
    return null;
  };

  const getIrrPeriodLabel = () => {
    let irrPeriodLabel = "";
    if (selectedCagrTimePeriod === timePeriods.YTD) {
      irrPeriodLabel = " • YTD";
    } else if (selectedCagrTimePeriod === timePeriods.YEAR) {
      irrPeriodLabel = " • 1Y";
    }

    return irrPeriodLabel;
  };

  const getNetworthChange = forInvestable => {
    try {
      if (hasNetworthData === false) {
        return null;
      }

      const netWorthDataPoints = networthData.data[getChartRangeFromTimePeriod(selectedCagrTimePeriod)].netWorth;
      const netWorthTicker = getTickerUsingId(
        networthData.data[getChartRangeFromTimePeriod(selectedCagrTimePeriod)].tickerId
      ).shortName;
      const portfolioTicker = portfolio.currency;

      if (netWorthDataPoints.length < 2) {
        return null;
      }
      const startPoint = (() => {
        let tempStartPoint;
        if (forInvestable) {
          tempStartPoint = netWorthDataPoints.find(point => point.investibleTotal > 0);
        } else {
          tempStartPoint = netWorthDataPoints.find(point => point.value > 0);
        }

        if (tempStartPoint) {
          return tempStartPoint;
        }

        return netWorthDataPoints[0];
      })();

      const startValue = convertCurrency(
        forInvestable ? startPoint.investibleTotal : startPoint.value,
        netWorthTicker,
        portfolioTicker
      );
      const endValue = convertCurrency(
        forInvestable
          ? netWorthDataPoints[netWorthDataPoints.length - 1].investibleTotal
          : netWorthDataPoints[netWorthDataPoints.length - 1].value,
        netWorthTicker,
        portfolioTicker
      );

      return {
        startValue: startValue,
        startDate: startPoint.date,
        endValue: endValue,
        endDate: netWorthDataPoints[netWorthDataPoints.length - 1].date
      };
    } catch (e) {
      return null;
    }
  };

  const getNetWorthCagr = netWorthChange => {
    if (!netWorthChange === true) {
      return null;
    }
    if (new Date().getTime() - netWorthChartStartDate.getTime() < 30 * 24 * 60 * 60 * 1000) {
      return null;
    }
    return getCagr(
      netWorthChange.startValue,
      netWorthChange.endValue,
      netWorthChange.startDate,
      netWorthChange.endDate
    );
  };

  const getSelectedIndexesDetails = () => {
    return supportedIndexes.filter(item => selectedIndexes.includes(item.id));
  };

  const getIndexIrrs = () => {
    if (hasNetworthData === false || !networthData.data.market === true) {
      return null;
    }
    return { ...supportedIndexesIrr, start_date: networthData.data.market };
  };

  const netWorthChange = getNetworthChange();
  const investableChange = getNetworthChange(true);
  const supportedIndexes = useSelector(supportedIndicesSelector);
  const supportedIndexesIrr = useSelector(supportedIndicesIrrSelector);
  const selectedIndexes = useSelector(irrComparisonIndexesSelector);
  const getUnrealizedGainForACustodianWithState = useSelector(getUnrealizedGainForACustodianReselectFn);
  const indexIrrs = getIndexIrrs();

  const getInvestableAssetsCustodianList = () => {
    const investableAssets = (recapData && recapData.data && recapData.data.investableAssetsList) || [];
    const recapDataCurrency = recapData.currency;
    const result = [];
    for (const asset of investableAssets) {
      result.push({
        name: asset.name,
        value: convertCurrency(asset.value, recapDataCurrency, portfolio.currency)
      });
    }
    result.sort((a, b) => {
      return b.value - a.value;
    });
    return result.filter(item => !item.value === false);
  };
  const handleInvestableAssetsTipDismiss = () => {
    dispatch(updateUserPreferences({ isInvestableAssetsTipShown: true }));
  };

  const custodiansWithCashOnHand = useSelector(recapDataCashOnHandCustodiansSelector);
  const getCashOnHandCustodianList = () => {
    return custodiansWithCashOnHand;
  };
  const handleCashOnHandTipDismiss = () => {
    dispatch(updateUserPreferences({ isCashOnHandTipShown: true }));
  };

  const handleTaxOnUnrealizedGainsDialogTipClick = e => {
    dispatch(
      updateUserPreferences({
        isTaxOnUnrealizedGainsTipShown: true
      })
    );
  };

  const custodiansWithCommittedCapital = useSelector(recapDataCustodiansWithCommitedCapitalSelector);
  const getUnfundedCustodianList = () => {
    const result = custodiansWithCommittedCapital.map(custodian => {
      return {
        name: custodian.name,
        value: getUnfundedCommitmentForCustodian(custodian)
      };
    });
    result.sort((a, b) => {
      return b.value - a.value;
    });
    return result.filter(item => !item.value === false);
  };
  const handleUnfundedTipDismiss = () => {
    dispatch(updateUserPreferences({ isUnfunedTipShown: true }));
  };

  const custodiansWithTaxOnUnrealizedGains = useSelector(recapDataTaxOnUnrealisedGainsSelector);

  const getTaxOnUnrealizedGainsListItems = () => {
    const result = custodiansWithTaxOnUnrealizedGains.map(custodian => {
      const holdingsForACustodian = portfolio.details.custodian.filter(holding => holding.parentId === custodian.id);
      const unrealizedGain = getUnrealizedGainForACustodianWithState(
        custodian,
        categoryType.ASSET,
        holdingsForACustodian || []
      );
      return {
        name: custodian.name,
        value: custodian.linkedOwnership
          ? calcCustodianOwnershipValue(
              getEstimatedTaxOnUnrealizedGainsForCustodian(custodian, unrealizedGain),
              custodian.linkedOwnership
            )
          : getEstimatedTaxOnUnrealizedGainsForCustodian(custodian, unrealizedGain)
      };
    });
    result.sort((a, b) => {
      return b.value - a.value;
    });
    return result.filter(item => !item.value === false);
  };

  const showUnfundedCard = () => {
    return totalUnfundedCapital > 0 || !isUnfundedCardHiddenForAPortfolio(store.getState(), portfolio);
  };

  const netWorthCagr = getNetWorthCagr(netWorthChange);
  const investableCagr = getNetWorthCagr(investableChange);

  const getDataPoints = () => {
    if (!hasNetworthData) {
      return [];
    }

    const netWorthData = networthData.data[netWorthChartTimeRange];
    const netWorthDataPoints = netWorthData.netWorth.filter(dataPoint => {
      return new Date(dataPoint.date).getTime() >= netWorthChartStartDate.getTime();
    });

    if (netWorthDataPoints.length === 0) {
      return [];
    }

    return netWorthDataPoints;
  };

  const isDataEmpty = () => {
    if (!hasNetworthData) {
      return true;
    }
    return dataPoints.length < 2;
  };

  const getLabelDateString = date => {
    return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`.toUpperCase();
  };

  const getLineChartData = () => {
    if (isDataEmpty() === true) {
      return null;
    }

    const netWorthData = networthData.data[netWorthChartTimeRange];
    const netWorthDataPoints = dataPoints;
    const portfolioTicker = portfolio.currency;
    //const firstDataPoint = netWorthDataPoints[0];

    const data = {
      labels: netWorthDataPoints.map((dataPoint, index) => {
        const dateString = getLabelDateString(getDateInKuberaFormat(dataPoint.date));
        var label = dateString;
        return label;
      }),
      datasets: [
        {
          data: netWorthDataPoints.map(dataPoint =>
            convertCurrency(dataPoint.value, getTickerUsingId(netWorthData.tickerId).shortName, portfolioTicker)
          )
        }
      ]
    };

    return data;
  };

  const urlHashParams = getHashParams(location);
  const modalValue = urlHashParams[hashParams.MODAL];
  const handleTotalClick = modalValue => {
    if (recapData === 0) {
      return;
    }
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValue}`
    });
  };

  const dataPoints = getDataPoints();
  const lineChartData = getLineChartData();
  const hasChartData = dataPoints && dataPoints.length > 0;
  const shouldHideCagr = isAppInWhiteLabelMode() === true && siteConfig.showCagr === 0;
  const irrPeriodLabel = getIrrPeriodLabel();

  return (
    <FixedContainer>
      <Container direction={direction}>
        <NavigationSection>
          <AssetsNavigationLink
            to={!assetsTotal === true ? null : routes.ASSETS}
            direction={!assetsTotal === true ? null : "left"}
          >
            <NavigationTitle>{i18n.t("assets")}</NavigationTitle>
            <NavigationTotal>
              <NavigationTotalAnimate
                displayValue={`${shortFormatNumberWithCurrency(
                  assetsTotal,
                  portfolio.currency,
                  false,
                  !assetsTotal === false || useMaskedValues === true,
                  undefined,
                  undefined,
                  true
                )}`}
                fontSize={36}
              />
              {!assetsTotal === false && <RightArrowIcon />}
            </NavigationTotal>
          </AssetsNavigationLink>
          {getChangeLabels(assetsChangeTotalsAndContributors, false, routes.ASSETS)}
        </NavigationSection>
        {debtsTotal > 0 && (
          <NavigationSection>
            <NavigationLink
              to={!debtsTotal === true ? null : routes.DEBTS}
              direction={!debtsTotal === true ? null : "left"}
            >
              <NavigationTitle>{i18n.t("debts")}</NavigationTitle>
              <NavigationTotal>
                <NavigationTotalAnimate
                  displayValue={`${shortFormatNumberWithCurrency(
                    debtsTotal,
                    portfolio.currency,
                    false,
                    !debtsTotal === false || useMaskedValues === true,
                    undefined,
                    undefined,
                    true
                  )}`}
                  fontSize={36}
                />
                {!debtsTotal === false && <RightArrowIcon />}
              </NavigationTotal>
            </NavigationLink>
            {getChangeLabels(debtsChangeTotalsAndContributors, true, routes.DEBTS)}
          </NavigationSection>
        )}
        <NavigationSection>
          <NavigationLink
            to={!hasChartData === true ? null : routes.NET_WORTH}
            direction={!hasChartData === true ? null : "left"}
          >
            <NavigationTitle>{i18n.t("networth")}</NavigationTitle>
            <NetWorthNavigationTotal>
              <NavigationTotalAnimate
                displayValue={`${shortFormatNumberWithCurrency(
                  netWorth,
                  portfolio.currency,
                  false,
                  !netWorth === false || useMaskedValues === true,
                  undefined,
                  undefined,
                  true
                )}`}
                fontSize={36}
              />
              {hasChartData && <RightArrowIcon />}
            </NetWorthNavigationTotal>
          </NavigationLink>
          <NavigationLink onClick={e => handleTotalClick(modalValues.INVESTABLE_ASSETS)} direction="left">
            <NavigationTitle>{i18n.t("investableAssets")}</NavigationTitle>
            <NavigationTotal>
              {recapData !== 0 && (
                <>
                  <NavigationTotalAnimate
                    displayValue={`${shortFormatNumberWithCurrency(
                      investableAssets,
                      portfolio.currency,
                      false,
                      !investableAssets === false || useMaskedValues === true,
                      undefined,
                      undefined,
                      true
                    )}`}
                    fontSize={36}
                  />
                  <RightArrowIcon />
                </>
              )}
              {!recapData && <PlaceHolderValue>{"XXXX"}</PlaceHolderValue>}
            </NavigationTotal>
          </NavigationLink>
          {!netWorthChange === false && !netWorth === false && (
            <NetWorthChangeContainer>
              {!netWorthCagr === false && <CagrTitle>{i18n.t("cagr") + irrPeriodLabel}</CagrTitle>}
              <CagrContainer>
                {!netWorthCagr === false ? (
                  <CagrDetails>
                    <ChangeTitle>{i18n.t("networth")}</ChangeTitle>
                    <Cagr
                      value={netWorthCagr}
                      darkBackground={false}
                      updated={true}
                      alignPosition="left"
                      preventAnimation={true}
                      showZero={true}
                    />
                  </CagrDetails>
                ) : (
                  <CagrDetails isPlaceholder={true}>
                    <ChangeTitle>{i18n.t("networth")}</ChangeTitle>
                    <PlaceholderPercentage>{"+XX%"}</PlaceholderPercentage>
                  </CagrDetails>
                )}
                {!investableCagr === false ? (
                  <CagrDetails>
                    <ChangeTitle>{i18n.t("investable")}</ChangeTitle>
                    <Cagr
                      value={investableCagr}
                      darkBackground={false}
                      updated={true}
                      alignPosition="left"
                      preventAnimation={true}
                    />
                  </CagrDetails>
                ) : (
                  <CagrDetails isPlaceholder={true}>
                    <ChangeTitle>{i18n.t("investable")}</ChangeTitle>
                    <PlaceholderPercentage>{"+XX%"}</PlaceholderPercentage>
                  </CagrDetails>
                )}
              </CagrContainer>
              {shouldHideCagr === false && !netWorthChange === false && !netWorthCagr === false && (
                <IndexesIrrContainer>
                  {!indexIrrs === false &&
                    getSelectedIndexesDetails().map((item, index) => {
                      return (
                        <IndexesIrrDetails key={index}>
                          <ChangeTitle>{item.shortName}</ChangeTitle>
                          <IndexIrrValue
                            value={sanitizeIrr(indexIrrs[selectedCagrTimePeriod][item.id])}
                            darkBackground={false}
                            updated={true}
                            alignPosition="left"
                            preventAnimation={true}
                            showZero={true}
                          />
                        </IndexesIrrDetails>
                      );
                    })}
                </IndexesIrrContainer>
              )}
              {shouldHideCagr === false && !netWorthCagr === true && (
                <IndexesIrrContainer>
                  {!indexIrrs === false &&
                    getSelectedIndexesDetails().map((item, index) => {
                      return (
                        <IndexesIrrDetails key={index} isPlaceholder={true}>
                          <ChangeTitle>{item.shortName}</ChangeTitle>
                          <PlaceholderPercentage>{"+XX%"}</PlaceholderPercentage>
                        </IndexesIrrDetails>
                      );
                    })}
                </IndexesIrrContainer>
              )}
            </NetWorthChangeContainer>
          )}
        </NavigationSection>
        <BottomSection>
          <NavigationLink onClick={e => handleTotalClick(modalValues.CASH_ON_HAND_ASSETS)} direction="left">
            <NavigationTitle>{i18n.t("cashOnHand")}</NavigationTitle>
            <NavigationTotal>
              {recapData !== 0 && (
                <>
                  <NavigationTotalAnimate
                    displayValue={`${shortFormatNumberWithCurrency(
                      totalCashOnHand,
                      portfolio.currency,
                      false,
                      !totalCashOnHand === false || useMaskedValues === true,
                      undefined,
                      undefined,
                      true
                    )}`}
                    fontSize={36}
                  />
                  <RightArrowIcon />
                </>
              )}
              {!recapData && <PlaceHolderValue>{"XXXX"}</PlaceHolderValue>}
            </NavigationTotal>
          </NavigationLink>
          <NavigationLink onClick={e => handleTotalClick(modalValues.TAX_ON_UNREALIZED_GAINS)} direction="left">
            <NavigationTitle>{i18n.t("taxOnUnrealizedGain.title")}</NavigationTitle>
            <NavigationTotal>
              {recapData !== 0 && (
                <>
                  <NavigationTotalAnimate
                    displayValue={`${shortFormatNumberWithCurrency(
                      totalTaxOnUnrealizedGains,
                      portfolio.currency,
                      false,
                      !totalTaxOnUnrealizedGains === false || useMaskedValues === true,
                      undefined,
                      undefined,
                      true
                    )}`}
                    fontSize={36}
                  />
                  <RightArrowIcon />
                </>
              )}
              {!recapData && <PlaceHolderValue>{"XXXX"}</PlaceHolderValue>}
            </NavigationTotal>
          </NavigationLink>
          {showUnfundedCard() && (
            <NavigationLink onClick={e => handleTotalClick(modalValues.UNFUNDED_ASSETS)} direction="left">
              <NavigationTitle>{i18n.t("unfunded")}</NavigationTitle>
              <NavigationTotal>
                {recapData !== 0 && (
                  <>
                    <NavigationTotalAnimate
                      displayValue={`${shortFormatNumberWithCurrency(
                        totalUnfundedCapital,
                        portfolio.currency,
                        false,
                        !totalUnfundedCapital === false || useMaskedValues === true,
                        undefined,
                        undefined,
                        true
                      )}`}
                      fontSize={36}
                    />
                    <RightArrowIcon />
                  </>
                )}
                {!recapData && <PlaceHolderValue>{"XXXX"}</PlaceHolderValue>}
              </NavigationTotal>
            </NavigationLink>
          )}
        </BottomSection>
        <LineChartContainer isVisible={hasChartData && lineChartData}>
          {hasChartData && lineChartData && (
            <LineChart
              data={lineChartData}
              chartHeight={153}
              adjustPageScroll={true}
              chartPadding={{
                left: 0,
                right: 0,
                top: 30,
                bottom: 0
              }}
              bgcolor={theme.chartBgColor}
              themeMode={theme.mode}
            />
          )}
        </LineChartContainer>
        {modalValue === modalValues.INVESTABLE_ASSETS && (
          <MobileCustodianListDialog
            title={i18n.t("investableAssets")}
            description={i18n.t("investableAssetsDialog.mobileDescription")}
            tip={i18n.t("investableAssetsDialog.tip")}
            knowMore={"https://help.kubera.com/article/47-what-is-investable-assets"}
            showTip={userPreferences.investableAssets === false}
            currency={portfolio.currency}
            total={investableAssets}
            listItems={getInvestableAssetsCustodianList()}
            onTipDismiss={handleInvestableAssetsTipDismiss}
          />
        )}
        {modalValue === modalValues.CASH_ON_HAND_ASSETS && (
          <MobileCustodianListDialog
            title={i18n.t("cashOnHand")}
            description={i18n.t("cashOnHandDialog.mobileDescription")}
            tip={i18n.t("cashOnHandDialog.tip")}
            knowMore={"https://help.kubera.com/article/86-what-is-cash-on-hand"}
            showTip={userPreferences.isCashOnHandTipShown === false}
            currency={portfolio.currency}
            total={totalCashOnHand}
            listItems={getCashOnHandCustodianList()}
            onTipDismiss={handleCashOnHandTipDismiss}
          />
        )}
        {modalValue === modalValues.UNFUNDED_ASSETS && (
          <MobileCustodianListDialog
            title={i18n.t("unfundedDialog.title")}
            description={i18n.t("unfundedDialog.mobileDescription")}
            tip={i18n.t("unfundedDialog.tip")}
            knowMore={"https://help.kubera.com/article/84-what-is-unfunded-commitments"}
            showTip={userPreferences.isUnfunedTipShown === false}
            currency={portfolio.currency}
            total={totalUnfundedCapital}
            listItems={getUnfundedCustodianList()}
            onTipDismiss={handleUnfundedTipDismiss}
          />
        )}
        {modalValue === modalValues.TAX_ON_UNREALIZED_GAINS && (
          <MobileCustodianListDialog
            title={i18n.t("taxOnUnrealizedGainDialog.title")}
            description={i18n.t("taxOnUnrealizedGainDialog.description")}
            tip={i18n.t("taxOnUnrealizedGainDialog.tip")}
            knowMore={"https://help.kubera.com/article/107-what-is-tax-on-unrealized-gain"}
            currency={portfolio.currency}
            total={totalTaxOnUnrealizedGains}
            listItems={getTaxOnUnrealizedGainsListItems()}
            showTip={userPreferences.isTaxOnUnrealizedGainsTipShown === false}
            onTipDismiss={handleTaxOnUnrealizedGainsDialogTipClick}
            showAdjustedNetWorth={true}
          />
        )}
      </Container>
    </FixedContainer>
  );
};

export default React.memo(MobileNavComponent);

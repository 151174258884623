import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import i18n from "locale/i18n";
import { useSelector, useDispatch } from "react-redux";

import {
  getCustodianHistoryFormattedDateString,
  getCapitalizedStr,
  adminRetryChargeClient,
  adminRemoveChargeClient,
  shortFormatNumberWithCurrency,
  wlClientSelector
} from "@kubera/common";

import { ReactComponent as ThreeDotMenuIcon } from "assets/images/options.svg";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import Spinner from "components/loader/Spinner";

const Container = styled.div`
  position: relative;
  display: table-row;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
`;

const Cell = styled.div`
  display: table-cell;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000;
  vertical-align: middle;
  width: ${({ width }) => (width ? width : null)};
  padding: 0 14px;
  white-space: nowrap;
`;

const ValueCell = styled(Cell)`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const CellAction = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const Error = styled.span`
  color: #ff0000;
`;

const Retry = styled.span`
  color: #0074fc;
  text-decoration: underline;
  cursor: pointer;
`;

const ThreeDotMenu = styled(ThreeDotMenuIcon)`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  path {
    transform: scale(0.3682);
    transform-origin: center;
  }
`;

const Loader = styled(Spinner)`
  position: absolute;
  right: 15px;
  top: 3px;
  width: 20px;
  height: 20px;
  margin-top: 1px;
`;

const PendingTxt = styled.span`
  color: #00a707;
`;

const PaymentsList = ({ index, userId, charge = {}, flash, handlePaymentError = () => {} }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const listMenuRef = useRef(null);

  const client = useSelector(state => wlClientSelector(state, userId));

  const createdDateFormatted = getCustodianHistoryFormattedDateString(new Date(charge.tsCreated * 1000));

  const [isRetryLoading, setIsRetryLoading] = useState(false);
  const [isThreeDotListLoaderShown, setIsThreeDotListLoaderShown] = useState(false);

  const isCardError =
    charge.tsModified > (client && client.paymentMethod && client.paymentMethod.tsModified)
      ? (charge.errorCode || "").startsWith("card_")
      : false;

  const handleOnActionClick = () => {
    if (charge.receiptUrl) {
      window.kuberaOpen(charge.receiptUrl, "_blank");
    }
  };

  const handleOnRetry = async () => {
    setIsRetryLoading(true);
    await dispatch(adminRetryChargeClient(charge.id, userId)).catch(() => {});
    setIsRetryLoading(false);
  };

  const handleListMenuShow = e => {
    if (listMenuRef.current.isVisible()) {
      listMenuRef.current.dismiss();
      return;
    }
    e.stopPropagation();
    const targetPosition = e.target.getBoundingClientRect();
    listMenuRef.current.show(
      [[contextMenuItemType.DELETE]],
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      e.target
    );
  };

  const handleListMenuSelection = async selection => {
    setIsThreeDotListLoaderShown(true);
    switch (selection) {
      case contextMenuItemType.DELETE:
        await dispatch(adminRemoveChargeClient(charge.id, userId)).catch(() => {});
        break;
      default:
        break;
    }
    setIsThreeDotListLoaderShown(false);
  };

  useLayoutEffect(() => {
    if (flash) {
      document.flashElement(null, containerRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (index === 0) {
      handlePaymentError(isCardError);
    }
  }, [index, isCardError, handlePaymentError]);

  return (
    <Container ref={containerRef}>
      <Cell width="100px">{createdDateFormatted}</Cell>
      <Cell width="100%">
        {isRetryLoading || !charge.errorCode ? (
          getCapitalizedStr(charge.description || charge.type)
        ) : (
          <Error>
            {i18n.t("accountCardDeclined.title")}.{" "}
            {!isRetryLoading && <Retry onClick={handleOnRetry}>{i18n.t("retry")}</Retry>}
          </Error>
        )}
      </Cell>
      <ValueCell>{shortFormatNumberWithCurrency(charge.amount / 100, "USD", undefined, true)}</ValueCell>
      <Cell width="90px">
        {charge.paid === 0 ? (
          <PendingTxt>{i18n.t("pending")}</PendingTxt>
        ) : (
          <CellAction onClick={handleOnActionClick}>
            {charge.receiptUrl ? (
              i18n.t("receipt")
            ) : (
              <>
                {!isThreeDotListLoaderShown && !isRetryLoading ? (
                  <ThreeDotMenu onClick={handleListMenuShow} />
                ) : (
                  <Loader />
                )}
              </>
            )}
          </CellAction>
        )}
        <ContextMenu ref={listMenuRef} onSelection={handleListMenuSelection} />
      </Cell>
    </Container>
  );
};

export default React.memo(PaymentsList);
